import { nhlSlugToLongname, theme } from '../constants';

export const bruinsBlack = '#010101';
export const bruinsGold = '#ffb81c';

export const defaultColors = ['#ffb81c', '#010101'];
const ahlColorPalette = ['#25427e', '#cd3f43'];

export const nhlTeamColorPalettes = {
  'Anaheim Ducks': ['#a2aaad', '#fc4c02', '#85714d', '#010101'],
  'Arizona Coyotes': ['#862633', '#ddcba4', '#010101'],
  'Boston Bruins': ['#ffb81c', '#010101'],
  'Buffalo Sabres': ['#041e42', '#a2aaad', '#ffb81c', '#c8102e'],
  'Calgary Flames': ['#c8102e', '#f1be48', '#010101'],
  'Carolina Hurricanes': ['#a2aaad', '#c8102e', '#010101'],
  'Chicago Blackhawks': ['#ff671f', '#ffd100', '#001871', '#010101'],
  'Colorado Avalanche': ['#236192', '#a4a9ad', '#6f263d', '#010101'],
  'Columbus Blue Jackets': ['#041e42', '#a4a9ad', '#c8102e'],
  'Dallas Stars': ['#006341', '#8a8d8f', '#010101'],
  'Detroit Red Wings': ['#c8102e'],
  'Edmonton Oilers': ['#00205b', '#cf4520'],
  'Florida Panthers': ['#041e42', '#b9975b', '#c8102e'],
  'Los Angeles Kings': ['#010101', '#a2aaad'],
  'Minnesota Wild': ['#154734', '#ddcba4', '#eaaa00', '#a6192e'],
  'Montreal Canadiens': ['#001e62', '#a6192e'],
  'Nashville Predators': ['#041e42', '#ffb81c'],
  'New Jersey Devils': ['#c8102e', '#010101'],
  'New York Islanders': ['#003087', '#fc4c02'],
  'New York Rangers': ['#0033a0', '#c8102e'],
  'Ottawa Senators': ['#c8102e', '#010101', '#c69214'],
  'Philadelphia Flyers': ['#fa4616', '#010101'],
  'Pittsburgh Penguins': ['#010101', '#ffb81c'],
  'San Jose Sharks': ['#e57200', '#006272', '#010101'],
  'Seattle Kraken': ['#a7d1d1', '#081a28', '#c0092c'],
  'St. Louis Blues': ['#041e42', '#ffb81c', '#003087'],
  'Tampa Bay Lightning': ['#00205b'],
  'Toronto Maple Leafs': ['#152558', '#c6c6c6', '#ffffff'],
  'Vancouver Canucks': ['#00205b', '#97999b', '#041c2c'],
  'Vegas Golden Knights': ['#b4975a', '#333f42', '#010101'],
  'Washington Capitals': ['#041e42', '#a2aaad', '#782f40', '#a6192e'],
  'Winnipeg Jets': ['#041e42', '#c8102e'],
};

export const getTeamColorPalette = (teamSlug) => {
  if (teamSlug in nhlSlugToLongname) {
    return nhlTeamColorPalettes[nhlSlugToLongname[teamSlug]];
  } else if (teamSlug.startsWith('ahl')) {
    if (teamSlug === 'ahl.pro') {
      return nhlTeamColorPalettes['Boston Bruins'];
    } else {
      return ahlColorPalette;
    }
  } else {
    return defaultColors;
  }
};

export const getTeamColorPaletteFromName = (longname) => {
  if (longname in nhlTeamColorPalettes) {
    return nhlTeamColorPalettes[longname];
  } else if (longname == 'Providence Bruins') {
    return nhlTeamColorPalettes['Boston Bruins'];
  } else {
    return ahlColorPalette;
  }
};

export const getColorWithOpacity = (color, opacity) => {
  var _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
  return color + _opacity.toString(16).toUpperCase();
};

export const applyAlphaMultToHSLAString = (hslaString, alphaMult) => {
  if (!hslaString) return hslaString;
  const hsla = hslaString.replace('hsl(', '').replace(')', '').split(',');
  const newAlpha = parseFloat(hsla[3]) * alphaMult;

  return `hsl(${hsla[0]}, ${hsla[1]}, ${hsla[2]}, ${newAlpha}%)`;
};

export const plotlyColorPalette = [
  '#1f77b4', // muted blue
  '#ff7f0e', // safety orange
  '#2ca02c', // cooked asparagus green
  '#d62728', // brick red
  '#9467bd', // muted purple
  '#8c564b', // chestnut brown
  '#e377c2', // raspberry yogurt pink
  '#7f7f7f', // middle gray
  '#bcbd22', // curry yellow-green
  '#17becf', // blue-teal
];

export const getContrastYIQ = (hexcolor) => {
  // Remove the hash at the start if it's there
  hexcolor = hexcolor.replace(/^#/, '');

  // Parse the r, g, b values
  let r = parseInt(hexcolor.substr(0, 2), 16);
  let g = parseInt(hexcolor.substr(2, 2), 16);
  let b = parseInt(hexcolor.substr(4, 2), 16);

  // Calculate the YIQ value
  let yiq = (r * 299 + g * 587 + b * 114) / 1000;

  // Return black or white depending on the contrast
  return yiq >= 128 ? theme.colors.light.text.primary : theme.colors.dark.text.primary;
};
