import { faGavel } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import BDDSortableTable from 'components/bdd/bddsortabletable';
import { IconButton } from 'components/bdd/Button';
import { useButtonGroup } from 'components/bdd/ButtonGroup';
import { Divider } from 'components/bdd/Divider';
import Icon from 'components/bdd/Icon';
import { Column, Container, Row } from 'components/bdd/Layout';
import { Cell, ExportDataButton, StyledTable } from 'components/bdd/Table';
import { Typography } from 'components/bdd/Typography';
import { BFBoughtOutTag } from 'components/PlayersV2/Contract/components/tags/BFBoughtOutTag';
import { BFElcTag } from 'components/PlayersV2/Contract/components/tags/BFELCTag';
import { BFNTCTag } from 'components/PlayersV2/Contract/components/tags/BFNMCTag';
import { BFPerformanceBonusTag } from 'components/PlayersV2/Contract/components/tags/BFPerformanceBonusTag';
import { BFTerminatedTag } from 'components/PlayersV2/Contract/components/tags/BFTerminatedTag';
import { BFThirtyFivePlusTag } from 'components/PlayersV2/Contract/components/tags/BFThirtyFivePlusTag';
import { BFTwoWayTag } from 'components/PlayersV2/Contract/components/tags/BFTwoWayTag';
import { BFWaiverTag } from 'components/PlayersV2/Contract/components/tags/BFWaiverTag';
import PlayerLinkHover from 'components/PlayersV2/Hover/PlayerLinkHover';
import { TooltipSpan } from 'components/reports';
import { theme } from 'constants/theme';
import { formatDollars } from 'helpers/contracts';
import { roundToX, strContains } from 'helpers/data';
import {
  getNextOrCurrentSeason,
  getSeasonPlusMinusYears,
  positionToFDG,
  season8to4,
  seasonToEndYear,
  seasonToStartYear,
} from 'helpers/hockeyutils';
import { sortNumeric, sortWithNaN } from 'helpers/tables';
import { useMemo } from 'react';
import { Button } from 'react-bootstrap';
import { ArrowBarDown, ArrowDownCircle, ArrowLeftRight, ArrowRightCircleFill, ArrowUpCircle, Asterisk, Cash, ClockHistory, HeartPulse, Pen, PencilSquare, PlusCircle, ThreeDots, Trash, XCircle } from 'react-bootstrap-icons';
import styled from 'styled-components';
import { CapHitAddModal } from './CapHitAddModal';
import { getColorWithOpacity } from 'helpers/plotting';
import { HoverInteractive } from 'components/bdd';
import { LinkPopover } from 'components/bdd/Popover';

const skipStatuses = [
  'Junior',
  'Minor',
  'LongTermInjuredReserve',
  'InjuredReserve',
  'SeasonOpeningInjuredReserve',
];
const sections = [
  {
    key: 'forwards',
    label: 'Forwards',
    filter: (p) => positionToFDG(p.position) === 'f' && !skipStatuses.includes(p.status),
  },
  {
    key: 'defensemen',
    label: 'Defensemen',
    filter: (p) => positionToFDG(p.position) === 'd' && !skipStatuses.includes(p.status),
  },
  {
    key: 'goalies',
    label: 'Goalies',
    filter: (p) => positionToFDG(p.position) === 'g' && !skipStatuses.includes(p.status),
  },
  {
    key: 'freeAgents',
    label: 'Free Agents',
    filter: (p) => !p.allContractSeasons?.length && !!p.mostRecentExpiryStatus
  },
  {
    key: 'ir',
    label: 'Injured Reserve',
    filter: (p) => p.status === 'InjuredReserve',
  },
  {
    key: 'ltir',
    label: 'Long-Term Injured',
    filter: (p) => p.status === 'LongTermInjuredReserve',
  },
  {
    key: 'buried',
    label: 'Buried',
    filter: (p) => p.isBuried,
  },
];
const allFilter = (p) => p.allContractSeasons?.length > 0;

// these statuses each get their own section...
const separateStatuses = ['Nhl', 'InjuredReserve', 'LongTermInjuredReserve', 'SeasonOpeningInjuredReserve'];
const otherSections = [
  {
    key: 'soir',
    label: 'Season Opening IR',
    filter: (p) => p.status === 'SeasonOpeningInjuredReserve',
  },
  {
    key: 'other-f',
    label: 'Non-Roster Forwards',
    filter: (p) =>
      !separateStatuses.includes(p.status) && !p.isBuried && positionToFDG(p.position) === 'f',
  },
  {
    key: 'other-d',
    label: 'Non-Roster Defensemen',
    filter: (p) =>
      !separateStatuses.includes(p.status) && !p.isBuried && positionToFDG(p.position) === 'd',
  },
  {
    key: 'other-g',
    label: 'Non-Roster Goalies',
    filter: (p) =>
      !separateStatuses.includes(p.status) && !p.isBuried && positionToFDG(p.position) === 'g',
  },
];

const deadSections = [
  {
    key: 'agreement',
    label: 'Agreement',
    filter: (d) => d.category === 'AGREEMENT',
  },
  {
    key: 'retained',
    label: 'Retained',
    filter: (d) => d.category === 'RETAINED',
  },
  {
    key: 'buyout',
    label: 'Buyout',
    filter: (d) => d.category === 'BUYOUT',
  },
  {
    key: 'terminated',
    label: 'Terminated',
    filter: (d) => d.category === 'TERMINATED',
  },
  {
    key: 'overage',
    label: 'Past Season Overage',
    filter: (d) => d.category === 'PERF_OVERAGE',
  },
];

const getSeasonValue = (mode, sectionKey, bfPlayer, season) => {
  // Use the "currentCapHit" if available --since this will have retention
  // TODO -- once we have future seasons of data here, we should use those as well
  const hasRetainedCapHit = bfPlayer.currentCapHit?.retainedCapHit > 0;

  // If this player is already expired...
  if (seasonToStartYear(season) == bfPlayer.mostRecentFreeAgentYear) {
    return {
      type: 'expiry',
      value: bfPlayer.mostRecentExpiryStatus,
      bfPlayer,
      arb: strContains(
        bfPlayer.mostRecentExpiryStatusDetails,
        'Arbitration Eligible'
      ),
    }
  }
  // If this is the expiration season, return expiry details
  if (season == bfPlayer?.freeAgentSeason?.season) {
    return {
      type: 'expiry',
      value: bfPlayer.freeAgentSeason.expiryStatus,
      bfPlayer,
      arb: strContains(
        bfPlayer.freeAgentSeason.expiryStatusDetails,
        'Arbitration Eligible'
      ),
    };
  }

  const contractSeason = bfPlayer.allContractSeasons?.find((cs) => cs.season == season);
  if (!contractSeason) return { type: mode, value: null };

  if (mode === 'capHit') {
    // For soir we show their special cap hit (if current season)
    if (sectionKey === 'soir' && season == getNextOrCurrentSeason()) {
      return {
        type: mode,
        value: bfPlayer.currentCapHit?.soirCapHit,
        contractSeason,
        bfPlayer,
        isTwoWay: contractSeason.isTwoWay,
        isSOIR: true        
      }
    }

    const activeContractSeason = bfPlayer.allContractSeasons.find(
      (cs) => cs.season == getNextOrCurrentSeason()
    );


    // If there's retention and this season is still on the active contract, use the retention
    if (
      !!activeContractSeason &&
      hasRetainedCapHit &&
      activeContractSeason?.contractId == contractSeason.contractId
    ) {
      const value = !!bfPlayer.isBuried 
        ? bfPlayer.currentCapHit?.buriedCapHit 
        : bfPlayer.currentCapHit?.finalCapHit;
      return {
        type: mode,
        value,
        contractSeason,
        bfPlayer,
        isTwoWay: activeContractSeason.isTwoWay,
        hasRetention: hasRetainedCapHit,
        isBuried: !!bfPlayer.isBuried,
        unburiedCapHit: bfPlayer.currentCapHit?.finalCapHit,
      };
    }
  }

  if (mode === 'aav') {
    return {
      type: mode,
      value: contractSeason.totalSalary + contractSeason.performanceBonus,
      contractSeason,
      bfPlayer,
    };
  }
  if (mode === 'clauses') {
    return { type: mode, value: contractSeason.clauseDetails, contractSeason, bfPlayer };
  }
  if (season === getNextOrCurrentSeason() && sectionKey === 'buried' && mode === 'capHit') {
    return { 
      type: mode, 
      value: bfPlayer.currentCapHit?.buriedCapHit, 
      isBuried: true,
      unburiedCapHit: bfPlayer.currentCapHit?.finalCapHit,
      contractSeason,
      bfPlayer,
    };
  }
  return {
    type: mode,
    value: contractSeason?.[mode],
    isTwoWay: contractSeason?.isTwoWay,
    contractSeason,
    bfPlayer,
  };
};

const getDeadSeasonValue = (mode, sectionKey, deadCap, season) => {
  if (mode === 'capHit') {
    const capHits = JSON.parse(deadCap.seasons)
      .filter((s) => s.season == season)
      .map((s) => s.cap_hit);
    return { type: mode, value: capHits ? capHits[0] : null };
  } else {
    return { type: mode, value: null };
  }
};


const getDownloadColumns = (mode, seasons) => ([
  {
    id: 'slug',
    Header: 'slug',
    accessor: (d) => d.type === 'player'
      ? d.slug
      : d.playerSlug
  },
  {
    id: 'name',
    Header: 'Name',
    accessor: (d) => d.type === 'player'
      ? `${d.firstName} ${d.lastName}`
      : d.type === 'deadspace'
      ? `${d.bfPlayer?.firstName} ${d.bfPlayer?.lastName}`
      : d.type === 'header'
      ? d.label
      : null
  },
  {
    id: 'waiversRequired',
    Header: 'Needs Waivers',
    accessor: (d) => d.type === 'player'
      ? d.waiversRequired ? 'Y' : 'N'
      : null
  },
  {
    id: 'nmc',
    Header: 'No Move Clause',
    accessor: (d) => d.type === 'player'
      ? d.noMoveTrade
      : null
  },
  {
    id: 'position',
    Header: 'Position',
    accessor: (d) => d.type === 'player'
      ? d.position
      : null
  },
  {
    id: 'acquired',
    Header: 'Acquired',
    accessor: (d) => d.type === 'player'
      ? d.acquired
      : null
  },
  {
    id: 'age',
    Header: 'Age',
    accessor: (d) => d.type === 'player'
      ? d.age
      : null
  }
].concat(seasons.map((s) => ({
  id: s,
  Header: s,
  accessor: (d) => d.type === 'player'
    ? getSeasonValue(mode, d.sectionKey, d, s).value
    : d.type === 'deadspace' 
    ? getDeadSeasonValue(mode, d.sectionKey, d, s).value
    : null
}))));


export const TeamPlayerCapHitTables = ({ bfPlayers=[], deadSpace=[], startSeason, topRightContent, includeActions, actionsDisabled, actionCallbacks={} }) => {
  const { buttonGroup, selectedValue } = useButtonGroup({
    initialSelectedValue: 'capHit',
    options: [
      { label: 'Cap Hit', value: 'capHit' },
      // { label: 'AAV', value: 'aav' },
      { label: 'Tot. Sal', value: 'totalSalary' },
      { label: 'Base Sal', value: 'baseSalary' },
      { label: 'Sign. Bonus', value: 'signingBonus' },
      { label: 'Perf. Bonus', value: 'performanceBonus' },
      // { label: 'Clauses', value: 'clauses' },
    ],
  });

  // build download data so that each row has a type: header/player/deadspace
  let downloadColumns;
  let downloadData;
  try {
    downloadData = sections.flatMap(({ key, label, filter }) => {
      const players = bfPlayers.filter((p) => (key === 'freeAgents' || allFilter(p)) && filter(p));
      if (!players.length) return [];
      return [{ type: 'blank' }, { type: 'header', label }].concat(players.map((p) => ({ ...p, type: 'player', sectionKey: key })))
    }).concat(
      deadSections.flatMap(({ key, label, filter }) => {
        const ds = deadSpace.filter((d) => filter(d));
        if (!ds.length) return [];
        if (!deadSpace.length) return [];
        return [{ type: 'blank' }, { type: 'header', label }].concat(ds.map((d) => ({ ...d, type: 'deadspace', sectionKey: key })))
      })
    ).concat(
      otherSections.flatMap(({ key, label, filter }) => {
        const players = bfPlayers.filter((p) => (key === 'freeAgents' || allFilter(p)) && filter(p));
        if (!players.length) return [];
        return [{ type: 'blank' }, { type: 'header', label }].concat(players.map((p) => ({ ...p, type: 'player', sectionKey: key })))
      })
    );
    const seasons = useMemo(() => {
      let maxSeason = -1;
      bfPlayers.forEach(p => {
        p.activeAndFutureContracts?.forEach(c => {
          maxSeason = Math.max(maxSeason, c.endSeason)
        });
      });
      const seasons = [];
      const startYear = seasonToStartYear(!!startSeason ? startSeason : getNextOrCurrentSeason());
      for (let i = startYear; i <= seasonToEndYear(maxSeason); i++) {
        seasons.push(parseInt(`${i}${i+1}`));
      }
      return seasons
    }, [bfPlayers]);
    downloadColumns = getDownloadColumns(selectedValue, seasons);
  } catch (e) {
    console.error(e);
  }
    

  return (
    <Container paddingTop={2}>
      <Column gap={3}>
        <Row gap={8} justifyContent='space-between'>
          <Row gap={4}>
            <Container>{buttonGroup}</Container>
            {!!downloadData && !!downloadColumns && <ExportDataButton 
              data={downloadData} 
              columns={downloadColumns}
            />}
          </Row>
          
          {topRightContent}
        </Row>
        {sections.map(({ key, label, filter }) => {
          const players = bfPlayers.filter((p) => (key === 'freeAgents' || allFilter(p)) && filter(p));
          if (!players.length) return null;
          return <Container key={key}>
            <TeamPlayerCapHitTable
              sectionKey={key}
              startSeason={startSeason}
              header={label}
              bfPlayers={players}
              mode={selectedValue}
              includeActions={includeActions}
              actionsDisabled={actionsDisabled}
              actionCallbacks={actionCallbacks}
            />
            <Divider />
          </Container>
        })}
        <Container key="deadcap">
          <Column gap={3}>
            {deadSections.map(({ key, label, filter }) => {
              const ds = deadSpace.filter((d) => filter(d));
              if (!ds.length) return null;
              return <Container key={key}>
                <TeamDeadSpaceTable
                  header={label}
                  deadSpace={ds}
                  startSeason={startSeason}
                  mode={selectedValue}
                  includeActions={includeActions}
                  actionsDisabled={actionsDisabled}
                  actionCallbacks={actionCallbacks}
                />
                <Divider />
              </Container>
            })}
          </Column>
        </Container>
        {otherSections.map(({ key, label, filter }) => {
          const players = bfPlayers.filter((p) => allFilter(p) && filter(p));
          if (!players.length) return null;
          return <Container key={key}>
            <TeamPlayerCapHitTable
              sectionKey={key}
              header={label}
              startSeason={startSeason}
              bfPlayers={players}
              mode={selectedValue}
              includeActions={includeActions}
              actionsDisabled={actionsDisabled}
              actionCallbacks={actionCallbacks}
            />
            <Divider />
          </Container>
        })}
      </Column>
    </Container>
  );
};

const Styles = styled.div({
  table: {
    th: {
      borderBottom: '1px solid #333',
      padding: '1px 4px',
    },
    td: {
      padding: '1px 4px',
    },
    'tr:nth-child(odd)': {
      td: {
        background: '#f1f1f1',
      },
    },
  },
});
export const TeamPlayerCapHitTable = ({
  sectionKey,
  header,
  bfPlayers, // with loaded attributes "currentCapHit", "contracts", "contracts.contractSeasons"
  mode = 'capHit',
  startSeason, // defaults to nextOrCurrentSeason
  numSeasons = 6, // number of season columns for table.  If "auto" will do max required
  includeActions,
  actionsDisabled,
  actionCallbacks={}
}) => {
  const seasons = useMemo(() => {
    const currStartYear = seasonToStartYear(!!startSeason ? startSeason : getNextOrCurrentSeason());

    if (numSeasons == 'auto') {
      const maxSeason = bfPlayers.reduce((maxSeason, currPlayer) => {
        if (currPlayer.allContractSeasons.length === 0) return maxSeason;
        const currMaxSeason =
          currPlayer.allContractSeasons[currPlayer.allContractSeasons.length - 1].season;
        return currMaxSeason > maxSeason ? currMaxSeason : maxSeason;
      }, getNextOrCurrentSeason());

      var maxStartYear = seasonToStartYear(maxSeason);
    } else {
      maxStartYear = currStartYear + numSeasons - 1;
    }
    const ret = [];
    for (let year = currStartYear; year <= maxStartYear; year++) {
      ret.push(parseInt(`${year}${year + 1}`));
    }
    return ret;
  }, [bfPlayers]);
  const maxSeason = seasons[seasons.length - 1];

  const renderValue = ({ type, value, arb, isBuried, unburiedCapHit, isSOIR, hasRetention, bfPlayer, contractSeason }) => {
    if (value == undefined) return null;
    if (type === 'clauses') return value;
    if (type === 'expiry') return <ExpirationSeason bfPlayer={bfPlayer} value={value} arbEligible={arb} handleSign={actionCallbacks?.handleSign} clickToSign={includeActions && !actionsDisabled} />;
    const style = (isBuried || isSOIR) ? { textDecoration: 'underline', textDecorationStyle: 'dashed' } : {};
    const tooltip = (
      <Column>
        {!!contractSeason && <Typography variant='stat'>{season8to4(contractSeason.season, true)}</Typography>}
        {!!contractSeason && <Typography variant='stat'>{contractSeason.clauseDetails}</Typography>}
        {!!contractSeason && <Typography variant='stat'>Total Salary: {formatDollars(contractSeason.totalSalary, { truncate: false })}</Typography>}
        {!!contractSeason && <Typography variant='stat'>Perf Bonus: {formatDollars(contractSeason.performanceBonus, { truncate: false })}</Typography>}
        
        {!!hasRetention && <Typography variant='body1'>
          {JSON.parse(bfPlayer.currentCapHit.retainedDetails)?.map((d) => `${d.team_slug.replace('nhl.', '').toUpperCase()} retain ${formatDollars(d.cap_hit)} (${roundToX(d.percent, 1)}%)`).join(', ')}
        </Typography>}

        {isBuried && <Typography variant='stat'>Unburied Cap Hit: {formatDollars(unburiedCapHit, { truncate: false })}</Typography>}

        {isSOIR && <Typography variant='stat'>Normal Cap Hit: {formatDollars(bfPlayer.currentCapHit?.finalCapHit, { truncate: false })}</Typography>}
      </Column>
    );
    return (
      <TooltipSpan style={style} content={(isSOIR || isBuried || !!contractSeason) ? tooltip : null}>
        {formatDollars(value, { truncate: false })}
      </TooltipSpan>
    );
  };

  const renderContinuationIcon = (player) => {
    if (player.isTotal) return null;
    if (!player.allContractSeasons?.length) return null;

    const lastSeason =
      player.allContractSeasons[player.allContractSeasons.length - 1].season;
    if (lastSeason > maxSeason) {
      return (
        <TooltipSpan content={`Player signed through ${season8to4(lastSeason, true)}`}>
          <Icon icon={<ArrowRightCircleFill />} color="green" size={'15px'} />
        </TooltipSpan>
      );
    }
  };

  const sumReducer = (acc, curr) => acc + curr;
  const columns = [
    ...(includeActions ? [{
      id: 'actions',
      Header: '',
      accessor: (p) => (
        <PlayerCapActions sectionKey={sectionKey} bfPlayer={p} {...actionCallbacks} disabled={actionsDisabled} />
      ),
      width: 50,
    }] : []),
    {
      id: 'header',
      Header: (
        <Typography variant="body1">
          {header} {bfPlayers.length > 0 && `(${bfPlayers.length})`}
        </Typography>
      ),
      Footer: (
        <Cell>
          <b>Totals</b>
        </Cell>
      ),
      accessor: (p) => {
        const contract = p.activeAndFutureContracts?.[0];
        const contractExcluded = p.status === 'Minor' && p.currentCapHit?.contractExcluded;
        return (
          <Row>
            <Container width={125}>
              <Row gap={4}>
                {contractExcluded && (
                  <Typography variant='body1' color="#777">J -</Typography>
                )}
                <PlayerLinkHover slug={p.bddPlayer?.slug} bf={p} variant={p.source === 'wrapper' ? 'body1' : 'body2'} />
              </Row>
            </Container>
            <BFElcTag bfPlayer={p} contract={contract} noBorder />
            <BFPerformanceBonusTag bfPlayer={p} contract={contract} noBorder />
            <BFThirtyFivePlusTag bfPlayer={p} contract={contract} noBorder />
            <BFNTCTag bfPlayer={p} noBorder />
            <BFWaiverTag bfPlayer={p} noBorder />
            <BFTerminatedTag bfPlayer={p} contract={contract} noBorder />
            <BFBoughtOutTag bfPlayer={p} contract={contract} noBorder />
          </Row>
        );
      },
    },
    {
      id: 'position',
      Header: <Typography variant="body1">Pos</Typography>,
      width: 50,
      accessor: (p) => <Typography variant="body2">{p.position}</Typography>,
    },
    {
      id: 'acquired',
      Header: <Typography variant="body1">Acquired</Typography>,
      width: 70,
      accessor: (p) => (
        <Typography variant="body2">
          <TooltipSpan content={p.acquiredDate}>{p.acquired}</TooltipSpan>
        </Typography>
      ),
    },
    {
      id: 'age',
      Header: <Typography variant="body1">Age</Typography>,
      accessor: (p) => <Typography variant="body2">{roundToX(p.age, 1)}</Typography>,
      sortType: sortNumeric,
      width: 50,
    },
  ].concat(
    seasons.map((s, i) => ({
      id: s,
      Header: <Typography variant="body1">{season8to4(s, true)}</Typography>,
      width: 100,
      sortType: (a, b, _) => {
        return sortWithNaN(
          getSeasonValue(mode, sectionKey, a.original, s).value,
          getSeasonValue(mode, sectionKey, b.original, s).value
        );
      },
      accessor: (p) => {
        const typeValue = getSeasonValue(mode, sectionKey, p, s);
        return (
          <TooltipSpan content={typeValue.tooltip}>
            <Typography
              variant={typeValue.isTwoWay ? 'body1' : 'body2'}
              color={
                typeValue.isTwoWay
                  ? theme.colors.light.text.green
                  : theme.colors.light.text.primary
              }
            >
              <Row gap={2}>
                {renderValue(typeValue)}
                {typeValue.hasRetention && <Icon icon={<Asterisk/>} size={6} />}
                {s == maxSeason && renderContinuationIcon(p)}
              </Row>
            </Typography>
          </TooltipSpan>
        );
      },
      Footer: (
        <Cell>
          <b>
            {renderValue({
              value: bfPlayers
                .map((p) => {
                  const seasonValue = getSeasonValue(mode, sectionKey, p, s);
                  return seasonValue.type !== 'expiry' ? seasonValue.value : 0;
                })
                .reduce(sumReducer, 0),
            })}
          </b>
        </Cell>
      ),
    }))
  ).concat(
    includeActions ? [{
      id: 'remove-actions',
      Header: '',
      accessor: (p) => (
        <Container>
          <IconButton 
            icon={<XCircle/>} 
            tooltip={`Remove ${p.source === 'wrapper' ? 'placeholder "' : ''}${p.firstName} ${p.lastName}${p.source === 'wrapper' ? '"' : ''}`} 
            onClick={() => {
              p.source === 'wrapper'
                ? actionCallbacks.handleRemoveWrapper(p)
                : actionCallbacks.handleRemove(p.bddPlayer.slug)
            }}
            disabled={actionsDisabled}
          />
        </Container>
      ),
      width: 20,
    }] : []
  )

  return (
    <StyledTable darkHeader>
      <BDDSortableTable
        columns={columns}
        data={bfPlayers}
        columnJustify="start"
        defaultSort={[{ id: getNextOrCurrentSeason(), desc: true }]}
        flexLayout
        hasFooter={bfPlayers.length > 1}
        trClassCallback={(row) => row.original.source === 'wrapper' ? 'greyed' : ''}
      />
    </StyledTable>
  );
};

const ExpirationSeason = ({ value, arbEligible = false, bfPlayer, clickToSign, handleSign }) => {
  const bddSlug = bfPlayer?.bddPlayer?.slug;
  const canSign = !!bddSlug && !!clickToSign && !!handleSign;
  const handleClick = () => {
    if (!clickToSign || !bddSlug) return;
    handleSign(bddSlug);
  }
  return (
    <Typography
      variant="body1"
      textAlign="center"
      color={theme.colors.light.secondary}
      onClick={handleClick}
      style={{
        width: '100%',
        borderRadius: '5px',
        background:
          value === 'RFA' ? theme.colors.categorical.blue : theme.colors.categorical.red,
      }}
    >
      <TooltipSpan content={canSign ? <Typography variant='stat'>Click to sign</Typography> : null}>
        {value} {arbEligible && <FontAwesomeIcon icon={faGavel} />}
      </TooltipSpan>
    </Typography>
  );
};

export const TeamDeadSpaceTable = ({
  header,
  deadSpace,
  startSeason,
  mode = 'capHit',
  numSeasons = 6, // number of season columns for table.  If "auto" will do max required
  includeActions,
  actionCallbacks
}) => {
  const seasons = useMemo(() => {
    const currStartYear = seasonToStartYear(!!startSeason ? startSeason : getNextOrCurrentSeason());
    const maxStartYear = currStartYear + numSeasons - 1;
    const ret = [];
    for (let year = currStartYear; year <= maxStartYear; year++) {
      ret.push(parseInt(`${year}${year + 1}`));
    }
    return ret;
  }, [deadSpace]);

  const renderValue = ({ type, value }) => {
    if (!!value && type == 'capHit') return formatDollars(value, { truncate: false });
    return null;
  };

  const columns = [
    ...(includeActions
      ? [{
          id: 'actions',
          width: 50,
          Header: '',
          accessor: (d) => ''
        }]
      : []
    ),
    {
      id: 'header',
      Header: <Typography variant="body1">{header}</Typography>,
      accessor: (d) => (
        d.category === 'PERF_OVERAGE'
          ? <Typography variant='body2' style={{ fontStyle: 'italic' }}>Performance Overage</Typography>
          : <PlayerLinkHover slug={d.bfPlayer?.bddPlayer?.slug} bf={d.bfPlayer} />
      ),
    },
    {
      id: 'empty',
      width: 170,
    },
  ].concat(
    seasons.map((s, i) => ({
      id: s,
      Header: <Typography variant="body1">{season8to4(s, true)}</Typography>,
      width: 100,
      accessor: (p) => {
        const typeValue = getDeadSeasonValue(mode, null, p, s);
        return (
          <Typography variant="body2">
            <Row gap={2}>{renderValue(typeValue)}</Row>
          </Typography>
        );
      },
    }))
  ).concat(
    includeActions ? [{
      id: 'remove-actions',
      Header: '',
      accessor: (ds) => (
        ds.source === 'scenario'
          ? <IconButton icon={<Trash/>} tooltip='Remove from scenario' onClick={() => actionCallbacks.handleRemoveDeadSpace(ds.id)} />
          : null
      ),
      width: 20,
    }] : []
  );

  return (
    <StyledTable darkHeader>
      <BDDSortableTable
        columns={columns}
        data={deadSpace}
        columnJustify="start"
        defaultSort={[{ id: getNextOrCurrentSeason(), desc: true }]}
        flexLayout
        trClassCallback={(row) => row.original.source === 'scenario' ? 'greyed' : ''}
      />
    </StyledTable>
  );
};


const PlayerCapActions = ({ 
  sectionKey, 
  bfPlayer, 
  handleCallUp, 
  handleSendDown, 
  handleIr, 
  handleLtir, 
  handleSign, 
  handleTrade, 
  handleBuyout,
  handleEditWrapper, 
  disabled 
}) => {
  const bddPlayer = bfPlayer.bddPlayer;
  const name = bfPlayer.firstName + ' ' + bfPlayer.lastName;
  if (bfPlayer.source === 'wrapper') {
    return (
      <Row justifyContent='center'>
        <IconButton icon={<PencilSquare />} onClick={() => handleEditWrapper(bfPlayer)} tooltip={`Edit placeholder "${name}"`} disabled={disabled} />
      </Row>
    )
  }

  const moreActions = (
    <LinkPopover
      hideTransition
      placement='right'
      renderLink={(setShow, target) => {
        return (
          <Container
            ref={target}
            padding={0.5}
            style={{}}
            variant="button"
            onClick={() => setShow((show) => !show)}
          >
            <Row columnGap={1}>
              <Typography variant="body2">
                <Icon
                  icon={<ThreeDots />}
                />
              </Typography>
            </Row>
          </Container>
        );
      }}
      renderContent={() => (
        <Container>
          <Column gap={2}>
            {(sectionKey != 'freeAgents' || bfPlayer.mostRecentExpiryStatus != 'UFA') && 
              <IconButton icon={<ArrowLeftRight/>} onClick={() => handleTrade(bddPlayer.slug)} tooltip={`Trade ${name}`} disabled={disabled} />
            }

            {(sectionKey != 'freeAgents' && bfPlayer.freeAgentSeason?.season == getSeasonPlusMinusYears(getNextOrCurrentSeason(), 1)) && 
              <IconButton icon={<Pen/>} onClick={() => handleSign(bddPlayer.slug)} tooltip={`Extend ${name}`} disabled={disabled} />
            }

            {(sectionKey != 'freeAgents') && 
              <IconButton icon={<Cash/>} onClick={() => handleBuyout(bfPlayer)} tooltip={`Buyout ${name}`} disabled={disabled} />
            }

            {['forwards', 'defensemen', 'goalies'].includes(sectionKey) && 
              <IconButton icon={<HeartPulse />} onClick={() => handleIr(bddPlayer.slug, 'InjuredReserve')} tooltip={`Put ${name} on IR`} disabled={disabled} />
            }

            {['forwards', 'defensemen', 'goalies', 'ir'].includes(sectionKey) && 
              <IconButton icon={<ClockHistory />} onClick={() => handleLtir(bddPlayer.slug)} tooltip={`Add ${name} to LTIR`} disabled={disabled} />
            }
          </Column>
        </Container>
      )}
    />
  )
  
  return (
    <Container>
      <Row gap={4} justifyContent='center'>
        {['buried', 'other-f', 'other-d', 'other-g'].includes(sectionKey) && <IconButton icon={<ArrowUpCircle />} onClick={() => handleCallUp(bddPlayer.slug)} tooltip={`Call Up ${name}`} disabled={disabled} />}
        {['forwards', 'defensemen', 'goalies'].includes(sectionKey) && <IconButton icon={<ArrowDownCircle />} onClick={() => handleSendDown(bddPlayer.slug)} tooltip={`Send Down ${name}`} disabled={disabled} />}
        {['freeAgents'].includes(sectionKey) && <IconButton icon={<Pen />}  onClick={() => handleSign(bddPlayer.slug)} tooltip={`Sign ${name}`} disabled={disabled} />}
        {['ir'].includes(sectionKey) && <IconButton icon={<ArrowUpCircle />} onClick={() => handleCallUp(bddPlayer.slug)} tooltip={`Activate ${name}`} disabled={disabled} />}
        {['ltir'].includes(sectionKey) && <IconButton icon={<ArrowUpCircle />} onClick={() => handleCallUp(bddPlayer.slug)} tooltip={`Activate ${name}`} disabled={disabled} />}
        {moreActions}
      </Row>
    </Container>
  )
}