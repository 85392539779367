import { useQuery } from '@apollo/client';
import { GET_PLAYLIST, GET_PLAYLISTS } from 'apollo/queries/video.queries';
import usePlaceholder from 'components/Placeholder/usePlaceholder';
import produce from 'immer';
import { create } from 'zustand';

const usePlaylistStore = create((set) => ({
  playlists: [],
  playlistById: {},
  actions: {
    loadPlaylists: (data) => {
      set(
        produce((state) => {
          state.playlists = [...data.bddPlaylists].sort(
            (a, b) => new Date(b.lastModified) - new Date(a.lastModified)
          );
        })
      );
    },
    addPlaylist: (data) => {
      set(
        produce((state) => {
          const id = data.bddPlaylist.id;

          state.playlistById[id] = {
            ...data.bddPlaylist,
            clips: [...data.bddPlaylist.clips].sort(
              (a, b) => a.playlistNumber - b.playlistNumber
            ),
          };

          state.playlists = [data.bddPlaylist, ...state.playlists];
        })
      );
    },
    updatePlaylist: (data) => {
      set(
        produce((state) => {
          const id = data.bddPlaylist.id;
          const updatedPlaylist = {
            ...data.bddPlaylist,
            clips: [...data.bddPlaylist.clips].sort(
              (a, b) => a.playlistNumber - b.playlistNumber
            ),
          };

          state.playlists = state.playlists.map((p) =>
            p.id == id ? updatedPlaylist : p
          );

          state.playlistById[id] = updatedPlaylist;
        })
      );
    },
  },
}));

export const usePlaylist = ({ id }) => {
  const playlist = usePlaylistStore(
    (state) => id in state.playlistById && state.playlistById[id]
  );

  const { updatePlaylist } = usePlaylistActions();
  const query = usePlaceholder(
    useQuery(GET_PLAYLIST, {
      variables: {
        id,
      },
      skip: !!playlist || !id,
      onCompleted: updatePlaylist,
    })
  );

  return { playlist, ...query };
};

export const usePlaylists = ({ onlyEdit } = {}) => {
  let playlists = usePlaylistStore((state) => state.playlists);
  const { loadPlaylists } = usePlaylistActions();

  const query = usePlaceholder(
    useQuery(GET_PLAYLISTS, {
      skip: playlists?.length > 0,
      onCompleted: loadPlaylists,
    })
  );

  playlists = playlists?.filter((p) => !p.isArchived);

  if (onlyEdit) {
    playlists = playlists?.filter((p) => p.canEdit);
  }

  return { playlists, ...query };
};

export const usePlaylistActions = () => {
  return usePlaylistStore((state) => state.actions);
};
