import React, { useState } from "react"
import styled from "styled-components"
import { Collapse, Card } from "react-bootstrap"

const Style = styled.div`
    margin: auto;
    max-width: 500px;

    // background: #A00;
    padding: 10px;
    color: #A00;
    border-radius: 10px;
    border: 2px solid #A00;

    cursor: pointer;
`

export default function BDDApiError({ title, error }) {
    const [open, setOpen] = useState(false)

    console.log(error.message)
    try {
        if (error.message) {
            var details = {
                type: null,
                code: null,
                msg: error.message
            }
        }
        if (error.networkError) {
            var details = {
                type: error.networkError.response.statusText,
                code: error.networkError.response.status,
                msg: error.networkError.result.message
            }
        } else if (error.graphQLErrors.length > 0) {
            var details = {
                msg: error.graphQLErrors[0].message,
                code: null,
                type: null
            }
        }
    } catch {
        var details = {
            type: null,
            code: null,
            msg: 'Details not found'
        }
    }
    return <Style  onClick={() => {
        setOpen(!open)
    }}>
        <h3>{title || 'Error fetching data'}</h3>
        <small>{details.type} {!!details.code ? <>({details.code}) - </> : null} {details.msg}</small>
        <Collapse in={open}>
            <pre>
                {JSON.stringify(error, null, 4)}
            </pre>
      </Collapse>
    </Style>
}