import { gql } from '@apollo/client';

export const SEARCH = gql`
  query Search(
    $term: String!
    $n: Int
    $minScore: Float
    $resultTypes: [SearchResultTypes!]
  ) {
    search(term: $term, n: $n, minScore: $minScore, resultTypes: $resultTypes) {
      score
      result {
        __typename
        ... on BDDPlayerSlug {
          slug
          firstname
          lastname
          slUid
          rinknetId
          rinknetPlayer {
            id
            currentteam
            currentteamnhlid
          }
        }
        ... on SLTeam {
          id
          nhlid
          slug
          longname
          shorthand
        }
        ... on Users {
          id
          name
          username
          __typename
        }
        ... on UserGroups {
          slug
          groupName: name
          description
          __typename
        }
      }
    }
  }
`;

export const GET_PLAYER_SEARCH_QUERY = gql`
  query Search($search: String!) {
    search(term: $search, resultTypes: [BDDPLAYERS]) {
      score
      result {
        __typename
        ... on BDDPlayerSlug {
          slug
          firstname
          lastname
          slUid
          rinknetId
          cfSlug
          bfSlug
          epId
          rinknetPlayer {
            id
            currentteam
            currentteamnhlid
          }
        }
      }
    }
  }
`;

export const GET_PLAYER_AND_CONTRACT_SEARCH_QUERY = gql`
  query Search($search: String!) {
    search(term: $search, resultTypes: [BDDPLAYERS]) {
      score
      result {
        __typename
        ... on BDDPlayerSlug {
          slug
          firstname
          lastname
          slUid
          rinknetId
          cfSlug
          epId
          rinknetPlayer {
            id
            currentteam
            currentteamnhlid
          }
          bfPlayer {
            slug
            status
            isActive
            freeAgentYear
            expiryStatus
          }
        }
      }
    }
  }
`;

export const GET_RINKNET_PLAYER_SEARCH_QUERY = gql`
  query Search($search: String!) {
    search(term: $search, resultTypes: [RINKNETPLAYERS]) {
      score
      result {
        __typename
        ... on RinknetPlayer {
          id
          realId
          firstname
          lastname
          position
          currentleague
          currentleagueabbr
          currentteam
          jerseynumber
        }
      }
    }
  }
`;
