import { useQuery } from '@apollo/client';
import { GET_PLAYLISTS } from 'apollo/queries/video.queries';
import { Card } from 'components/bdd/Card';
import Icon from 'components/bdd/Icon';
import { Column, Container, Row } from 'components/bdd/Layout';
import { ModalContext } from 'components/bdd/Modal';
import { Page } from 'components/bdd/Page';
import { Typography } from 'components/bdd/Typography';
import usePlaceholder from 'components/Placeholder/usePlaceholder';
import { useContext } from 'react';
import { Button } from 'react-bootstrap';
import { Archive, PlusLg } from 'react-bootstrap-icons';
import { AddPlaylistForm } from './AddPlaylistForm';
import { theme } from 'constants/theme';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { PLAYLISTS_ROUTE } from 'constants';
import {
  ArchivePlaylistIconButton,
  PlaylistLoadingIndicator,
  UpdatePlaylistIconButton,
  usePlaylists,
} from '.';
import { dateToTimeAgo } from 'helpers/helpers';
import { useUser } from 'helpers/user';
import { NoColorLink } from 'components/bdd/Button';
import { useSearchField } from 'components/bdd/Select/useSearchField';
import { strContains } from 'helpers/data';
import { getStringSimilarity, strCompare, strFindBestMatch } from 'helpers/string';
import { permissions } from 'constants';



export const PlaylistsPage = ({}) => {
  const { renderModal, hideModal } = useContext(ModalContext);
  const { searchComponent, searchValue } = useSearchField({ placeholder: 'Filter playlists...' });

  const routes = [
    {
      route: 'mine',
      name: 'My Playlists',
      render: () => <Playlists onlyMine searchValue={searchValue} />,
    },
    {
      route: 'shared',
      name: 'Shared With Me',
      render: () => <Playlists onlyShared searchValue={searchValue}/>,
    },
    {
      route: 'all',
      name: 'All Playlists',
      requiresPermission: permissions.adminFullAccess,
      render: () => <Playlists searchValue={searchValue} />,
    },
  ];

  return (
    <Page>
      <Page.Header
        title="Playlists"
        routes={routes}
        actionContent={
          <Button
            style={{ width: '120px' }}
            variant="outline-dark"
            onClick={() => {
              renderModal({
                title: <Typography variant="h6">Add Playlist</Typography>,
                body: <AddPlaylistForm onSubmit={hideModal} />,
              });
            }}
          >
            <Typography variant="body1">
              <Row columnGap={1}>
                <Icon icon={<PlusLg />} />
                Add Playlist
              </Row>
            </Typography>
          </Button>
        }
        secondaryActionContent={(
          <Container width={200}>
            {searchComponent}
          </Container>
        )}
      />
      <Page.Body>
        <Page.Router routes={routes} />
      </Page.Body>
    </Page>
  );
};

const Playlists = ({ onlyMine, onlyShared, searchValue }) => {
  const { user } = useUser();
  const { playlists, loading, error, placeholder } = usePlaylists();
  let allPlaylists = [...playlists]; // all
  let sourcePlaylists = onlyMine // filtered by tab
    ? allPlaylists.filter((p) => p.userId == user.id)
    : onlyShared
    ? allPlaylists.filter((p) => p.users.find((u) => u.user.id == user.id))
    : allPlaylists;

  let filteredPlaylists = sourcePlaylists; // filtered by term

  if (!!searchValue?.length) {
    const searchStringsLookup = sourcePlaylists.reduce((acc, p) => {
      acc[p.name.toLowerCase()] = p;
      return acc;
    }, {});
    const matches = strFindBestMatch(searchValue, Object.keys(searchStringsLookup));
    
    filteredPlaylists = matches.ratings
      .filter(({ rating }) => searchValue.length < 3 || rating > .1)
      .map(({ target }) => searchStringsLookup[target]);

    var bestMatch = searchValue.length > 3 && playlists.reduce((acc, curr) => {
      const score = strCompare(curr.name, searchValue);
      if (score > acc.score) {
        return { playlist: curr, score };
      }
      return acc
    }, { playlist: null, score: 0 });
    if (!bestMatch.playlist) bestMatch = null;
    var bestMatchFoundElsewhere = !!bestMatch && !sourcePlaylists?.find(p => p.id === bestMatch.playlist.id);
  }

  return (
    <Container>
      <Column gap={2}>
        {!!bestMatch?.playlist && bestMatchFoundElsewhere && (
          <PlaylistCard 
            key={bestMatch.playlist.id} 
            playlist={bestMatch.playlist} 
            warning={bestMatch.playlist.userId == user.id
              ? 'Found in "My Playlists"'
              : !!bestMatch.playlist.users.find(u => u.user.id == user.id)
              ? 'Found in "Shared Playlists"'
              : 'Found in "All Playlists"'}
          />
        )}
        {loading || error ? (
          placeholder
        ) : filteredPlaylists.length > 0 ? (
          filteredPlaylists.map((p) => {
            return <PlaylistCard key={p.id} playlist={p} />;
          })
        ) : (
          <Container padding={2}>
            <Typography variant="subtitle1">No playlists found</Typography>
          </Container>
        )}
      </Column>
    </Container>
  );
};

const PlaylistCard = ({ playlist, warning }) => {
  return (
    <NoColorLink as={Link} to={`${PLAYLISTS_ROUTE}/${playlist.id}`}>
      <Card
        topStripe={<Typography textAlign='center' variant='error'>{warning}</Typography>}
        variant="button"
        header={playlist.name}
        caption={
          <Row columnGap={3} rowGap={3} flexWrap>
            {playlist.description}
            <Typography variant="body2">
              Created by <b>{playlist.createdUser.name}</b>
            </Typography>
            <Typography variant="body2">
              Shared with {playlist.users.length} users
            </Typography>
            <Typography variant="body2">{playlist.clips.length} clips</Typography>
            <Typography variant="body2">
              Last modified {dateToTimeAgo(playlist.lastModified)}
            </Typography>
          </Row>
        }
        actionContent={
          <Container
            onClick={(e) => {
              e.preventDefault();
            }}
          >
            <Row columnGap={1}>
              <PlaylistLoadingIndicator playlist={playlist} />
              {playlist.canEdit && (
                <>
                  <UpdatePlaylistIconButton playlist={playlist} />
                  <ArchivePlaylistIconButton playlistId={playlist.id} />
                </>
              )}
            </Row>
          </Container>
        }
      ></Card>
    </NoColorLink>
  );
};
