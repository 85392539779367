import { getCurrentSeason } from 'helpers/hockeyutils';
import { getSeasonChoices } from 'helpers/filters';
import { useSelect } from '.';

const useSeasonSelect = ({ selectProps, variant='outlined' } = {}) => {
  const seasonOptions = getSeasonChoices();
  const { selectedValue, select } = useSelect({
    options: seasonOptions,
    initialSelectedValue: selectProps?.selectProps?.isMulti ? [getCurrentSeason()] : getCurrentSeason(),
    variant,
    ...selectProps,
  });

  return {
    season: selectedValue,
    seasonSelect: select,
  };
};

export default useSeasonSelect;
