import { gql } from '@apollo/client';
import { BDDPlaylistFragment, VideoPlaybackFragment } from './video.fragments';

export const GET_MEDIA_FOR_EVENTS = gql`
  query GetMediaForEvents($events: [SLEventInput!]!) {
    getMediaForEvents(events: $events) {
      ...videoPlaybackFragment
    }
  }
  ${VideoPlaybackFragment}
`;

export const GET_MEDIA_FOR_GAME = gql`
  query GetMediaForGame($gameUid: ID!, $period: Int) {
    getMediaForGame(gameUid: $gameUid, period: $period) {
      ...videoPlaybackFragment
    }
  }
  ${VideoPlaybackFragment}
`;

export const GET_MEDIA_FOR_FULL_GAME = gql`
  query GetMediaForFullGame($gameUid: ID!) {
    getMediaForFullGame(gameUid: $gameUid) {
      ...videoPlaybackFragment
    }
  }
  ${VideoPlaybackFragment}
`;

export const GET_MEDIA_FOR_SHIFTS = gql`
  query GetMediaForShifts($shiftIds: [ID!]!) {
    getMediaForShifts(shiftIds: $shiftIds) {
      ...videoPlaybackFragment
    }
  }
  ${VideoPlaybackFragment}
`;

export const GET_PLAYLISTS = gql`
  query GetPlaylists($onlyEdit: Boolean) {
    bddPlaylists(onlyEdit: $onlyEdit) {
      id
      userId
      name
      description
      isArchived
      canEdit
      clips {
        playlistNumber
        eventId
      }
      createdUser {
        name
      }
      lastModified
      users {
        canEdit
        user {
          id
          username
        }
      }
    }
  }
`;

export const GET_PLAYLIST = gql`
  query GetPlaylist($id: ID!) {
    bddPlaylist(id: $id) {
      ...bddPlaylistFragment
    }
  }
  ${BDDPlaylistFragment}
`;

export const CREATE_PLAYLIST = gql`
  mutation CreatePlaylist(
    $input: CreateBDDPlaylistInput!
    $users: [BDDPlaylistUserInput]
    $clips: [BDDPlaylistClipInput]
  ) {
    createBddPlaylist(input: $input, users: $users, clips: $clips) {
      bddPlaylist {
        ...bddPlaylistFragment
      }
    }
  }
  ${BDDPlaylistFragment}
`;

export const ARCHIVE_PLAYLIST = gql`
  mutation ArchivePlaylist($id: ID!) {
    archiveBddPlaylist(id: $id) {
      bddPlaylist {
        ...bddPlaylistFragment
      }
    }
  }
  ${BDDPlaylistFragment}
`;

export const UPDATE_PLAYLIST = gql`
  mutation UpdateBDDPlaylist(
    $input: BDDPlaylistInput!
    $clipsToRemove: [BDDPlaylistClipInput]
    $clipsToAdd: [BDDPlaylistClipInput]
    $clipsToUpdate: [BDDPlaylistClipInput]
    $usersToUpdate: [BDDPlaylistUserInput]
  ) {
    updateBddPlaylist(
      input: $input
      clipsToRemove: $clipsToRemove
      clipsToAdd: $clipsToAdd
      clipsToUpdate: $clipsToUpdate
      usersToUpdate: $usersToUpdate
    ) {
      bddPlaylist {
        ...bddPlaylistFragment
      }
    }
  }
  ${BDDPlaylistFragment}
`;
