import { buildQueryFilters } from 'components/bdd/Filters';
import { getFilterValues } from 'components/bdd/Filters/helpers';
import {
  DEFAULT_SKATER_SEASON_QUERY,
  GOALIE_STATS,
  LEAGUES_TO_IGNORE_BY_LEVEL,
  SKATER_STATS,
} from './constants';
import { formatDateGQL } from 'helpers/helpers';

export const buildQuery = ({ filters, statFilters, groupBy }) => {
  let query = {
    filters: [],
    extras: {},
  };

  const { position, gameType } = getFilterValues(filters);
  query.stats = position == 'G' ? GOALIE_STATS : SKATER_STATS;

  query.filters = buildQueryFilters(filters, () => ({
    position: (filter) => ({
      col: 'position',
      cond: 'isin',
      val: filter.value == 'skaters' ? ['F', 'D'] : [filter.value],
    }),
    level: (filter) => {
      const levelFilter = [
        {
          col: 'level',
          cond: 'isin',
          val: filter.value,
        },
      ];

      const leaguesToIgnore = filter.value.flatMap((v) =>
        v in LEAGUES_TO_IGNORE_BY_LEVEL ? LEAGUES_TO_IGNORE_BY_LEVEL[v] : []
      );

      return [
        levelFilter,
        ...(leaguesToIgnore.length > 0
          ? [
              {
                col: 'league_slug',
                cond: 'isnotin',
                val: leaguesToIgnore,
              },
            ]
          : []),
      ];
    },
  })).flat();

  query.filters = [...query.filters, ...statFilters.queryFilters];
  query.index = groupBy;
  query.extras.metastats = {
    target_group: ['league', 'age'],
    position: position == 'skaters' ? ['F', 'D'] : [position],
    game_type: gameType == 'null' ? null : gameType,
  };

  return { ...DEFAULT_SKATER_SEASON_QUERY, ...query };
};

export const getStatQueryOptions = ({ position }) => {
  const stats = position == 'G' ? GOALIE_STATS : SKATER_STATS;
  return stats.map((s) => ({
    col: `stats_${s}`,
    label: s,
    type: 'number',
    defaultCond: '>',
    step: s.includes('/GP') ? 0.1 : 1,
  }));
};

export const getDraftEligibilityFilter = (draftYear, offset = 0) => {
  const startDate = new Date(draftYear - 19 - offset, 8, 16);
  const endDate = new Date(draftYear - 18 - offset, 8, 15);

  return [
    {
      col: 'draft_year',
      cond: '=',
      val: null,
    },
    {
      col: 'birth_date',
      cond: '>=',
      val: formatDateGQL(startDate),
    },
    {
      col: 'birth_date',
      cond: '<=',
      val: formatDateGQL(endDate),
    },
  ];
};

export const sortAndFilterColumns = (columns, indexColumnIds) => {
  return columns
    .filter(
      (c) => !indexColumnIds || indexColumnIds.includes(c.id) || c.id.includes('stats_')
    )
    .sort(
      (a, b) => {
        const aInIndex = indexColumnIds && indexColumnIds.includes(a.id);
        const bInIndex = indexColumnIds && indexColumnIds.includes(b.id);

        if (aInIndex && !bInIndex) return -1;
        if (!aInIndex && bInIndex) return 1;
        return 0;
      }
    );
};
