import { IconButton } from 'components/bdd/Button';
import { Card } from 'components/bdd/Card';
import { DraggableList } from 'components/bdd/DragAndDrop';
import Icon from 'components/bdd/Icon';
import { Border, Column, Container, Row } from 'components/bdd/Layout';
import { ModalContext } from 'components/bdd/Modal';
import { Page } from 'components/bdd/Page';
import { LinkPopover } from 'components/bdd/Popover';
import { Typography } from 'components/bdd/Typography';
import {
  VideoContext,
  VideoContextProvider,
  VideoSelect,
  VideoSelectContext,
} from 'components/bdd/Video';
import { EventVideoPlayer } from 'components/bdd/Video/components';
import { Event, EventBank, EventControls } from 'components/bdd/Video/Filtering';
import usePlaceholder from 'components/Placeholder/usePlaceholder';
import { theme } from 'constants/theme';
import { useContext, useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import {
  FileEarmarkPlay,
  FileEarmarkPlayFill,
  PlayFill,
  PlusLg,
  Stickies,
  XLg,
} from 'react-bootstrap-icons';
import { useHistory, useParams } from 'react-router-dom';
import {
  AddVideoEvents,
  ArchivePlaylistIconButton,
  PlaylistLoadingIndicator,
  PlaylistVideoPlayer,
  UpdatePlaylistIconButton,
  usePlaylist,
  usePlaylistMutation,
} from '.';
import { CompactNotes, NoteCommentForm, useNotes, useNoteStore } from 'components/Notes';
import { NotificationIndicator } from 'components/bdd/NotificationIndicator';
import { EditPlaylistClipForm } from './EditPlaylistClipForm';
import { PLAYLISTS_ROUTE } from 'constants';
import { Shift } from 'components/bdd/Video/components/ShiftList';
import useToggle from 'components/bdd/Toggle/useToggle';

export const Playlist = ({}) => {
  const { id } = useParams();
  const history = useHistory();
  const { renderModal, hideModal } = useContext(ModalContext);
  const { playlist, loading, error, placeholder } = usePlaylist({ id });

  const cachedNotes = useNoteStore((state) =>
    state.notes.filter((n) => n.entityId == id && n.entityType == 'BDD_PLAYLIST')
  );

  const { updateNote } = useNotes({
    skip: cachedNotes.length > 0,
    entityIds: [id],
    entityType: 'BDD_PLAYLIST',
    variant: 'horizontal',
  });

  const noteItems = cachedNotes.map((n, index) => ({
    note: n,
    render: () => (
      <NoteCommentForm
        key={`note-${index}`}
        existingNote={n}
        variant="horizontal"
        {...n}
        onSubmit={(values) => {
          updateNote({ variables: values });
        }}
      />
    ),
  }));

  const clips = playlist?.clips;

  return (
    <Page>
      <Page.Header
        title={playlist?.name || 'Loading...'}
        subtitle={playlist?.description}
        actionContent={
          <Row columnGap={2}>
            <PlaylistLoadingIndicator playlist={playlist} />
            {playlist?.canEdit && (
              <>
                <UpdatePlaylistIconButton playlist={playlist} />
                <ArchivePlaylistIconButton
                  playlistId={id}
                  onArchive={() => history.replace(`${PLAYLISTS_ROUTE}`)}
                />
              </>
            )}
          </Row>
        }
        showHistoryBack
      />
      <Page.Body>
        {(loading || error) && placeholder}
        {playlist && (
          <Container height="100%">
            <VideoContextProvider
              videoContext={{
                playlist,
                allEvents: clips,
                eventType: 'clips',
              }}
            >
              <Card
                header={'Clips'}
                actionContent={
                  playlist.canEdit && (
                    <Button
                      variant="outline-dark"
                      onClick={() => {
                        renderModal({
                          size: 'xxl',
                          title: <Typography variant="h6">Add Video Events</Typography>,
                          body: (
                            <AddVideoEvents onSubmit={hideModal} playlist={playlist} />
                          ),
                        });
                      }}
                    >
                      <Typography variant="body1">
                        <Row columnGap={1}>
                          <Icon icon={<PlusLg />} />
                          Add Events
                        </Row>
                      </Typography>
                    </Button>
                  )
                }
              >
                <Container paddingTop={2} height="95%">
                  <VideoSelect>
                    <PlaylistEventBank playlist={playlist} noteItems={noteItems} />
                  </VideoSelect>
                </Container>
              </Card>
            </VideoContextProvider>
          </Container>
        )}
      </Page.Body>
    </Page>
  );
};

const PlaylistEventBank = ({ playlist, noteItems, onChange }) => {
  const [selectedClips, setSelectedClips] = useState([]);
  const [toggledClipForPlay, setToggledClipForPlay] = useState();

  const { setSelectedEvents } = useContext(VideoSelectContext);
  const sortedClips = [...playlist.clips].sort(
    (a, b) => a.playlistNumber - b.playlistNumber
  );
  const [playlistClips, setPlaylistClips] = useState(sortedClips);
  const { removeClipFromPlaylist, removeClipsFromPlaylist, reorderPlaylist, loading } =
    usePlaylistMutation(playlist);

  useEffect(() => {
    setPlaylistClips(sortedClips);
    setSelectedClips([]);
  }, [playlist]);

  const { renderModal } = useContext(ModalContext);
  const renderPlayer = (clips) => {
    setSelectedEvents(clips);
    renderModal({
      size: 'xxl',
      body: <PlaylistVideoPlayer autoPlay={false} playSelectedEvents={true} />,
    });
  };

  const { toggled: showNotes, toggleComponent: showNotesToggle } = useToggle({
    label: 'Show notes',
    initialToggled: true,
  });

  return (
    <Container height="100%">
      <Column gap={2}>
        {playlist.canEdit && (
          <Container>
            <Row justifyContent="space-between">
              <Row columnGap={4}>
                <Row columnGap={2}>
                  <input
                    style={{ width: 16, height: 16 }}
                    id="toggle-all-clips"
                    type="checkbox"
                    checked={playlistClips.length == selectedClips.length}
                    onChange={(event) => {
                      const checked = event.currentTarget.checked;

                      if (checked) {
                        setSelectedClips(() => playlistClips);
                      } else {
                        setSelectedClips([]);
                      }
                    }}
                  />
                  <label
                    htmlFor="toggle-all-clips"
                    style={{ margin: 0, userSelect: 'none' }}
                  >
                    <Typography variant="body1">Select All</Typography>
                  </label>
                </Row>
                {showNotesToggle}
              </Row>
              <Row columnGap={2}>
                <Button
                  disabled={loading}
                  variant="outline-dark"
                  onClick={() => {
                    if (selectedClips.length > 0) {
                      renderPlayer(selectedClips);
                    } else {
                      renderPlayer(playlistClips);
                    }
                  }}
                >
                  <Typography variant="body1">
                    <Row columnGap={2}>
                      <Icon icon={<PlayFill />} />
                      <div>{selectedClips.length == 0 ? 'Play All' : 'Play'}</div>
                    </Row>
                  </Typography>
                </Button>
                <LinkPopover
                  placement="left"
                  title="Edit clips"
                  renderLink={(setShow, target) => (
                    <Container ref={target}>
                      <Button
                        disabled={selectedClips.length == 0 || loading}
                        variant="outline-dark"
                        onClick={() => setShow(true)}
                      >
                        <Typography variant="body1">
                          <Row columnGap={2}>
                            <Icon icon={<FileEarmarkPlay />} />
                            <div>Edit</div>
                          </Row>
                        </Typography>
                      </Button>
                    </Container>
                  )}
                  renderContent={(forceClose) => (
                    <Container width={500}>
                      <EditPlaylistClipForm
                        playlist={playlist}
                        clips={selectedClips || playlistClips}
                        onSubmit={forceClose}
                      />
                    </Container>
                  )}
                />
                <Button
                  variant="outline-danger"
                  disabled={selectedClips.length == 0 || loading}
                  onClick={() => {
                    removeClipsFromPlaylist(selectedClips);
                  }}
                >
                  <Typography variant="body1">
                    <Row columnGap={2}>
                      <Icon icon={<XLg />} />
                      <div>Delete</div>
                    </Row>
                  </Typography>
                </Button>
              </Row>
            </Row>
          </Container>
        )}
        <Container height="100%" overflow="auto">
          <DraggableList
            disabled={loading || !playlist.canEdit}
            ids={playlistClips.map((e) => e.id)}
            renderItem={(id, index) => {
              const clip = playlistClips.find((e) => e.id == id);
              if (!clip) return null;

              const clipTouched =
                clip.name || clip.description || clip.frontPad || clip.backPad;

              const notesForClip = noteItems?.filter(
                (n) => n.note.subEntityId == clip.id
              );

              const clipContext = clip.eventId ? (
                <Event
                  number={index + 1}
                  event={clip.event}
                  name={clip.name}
                  description={clip.description}
                  selected={toggledClipForPlay == clip}
                  showBorder={false}
                />
              ) : (
                <Shift
                  number={index + 1}
                  shift={clip.shift}
                  name={clip.name}
                  description={clip.description}
                  isSelected={() => toggledClipForPlay == clip}
                  showBorder={false}
                />
              );

              return (
                <Container
                  key={`${index}-${clip.id}`}
                  style={{ borderBottom: '1px solid #333' }}
                >
                  <Row columnGap={2}>
                    <Row columnGap={2}>
                      <input
                        style={{ width: 16, height: 16 }}
                        type="checkbox"
                        checked={selectedClips.find((c) => c == clip)}
                        onChange={(event) => {
                          const checked = event.target.checked;

                          if (checked) {
                            setSelectedClips((clips) => [...clips, clip]);
                          } else {
                            setSelectedClips((clips) => clips.filter((c) => c != clip));
                          }
                        }}
                      />
                    </Row>
                    <Container
                      flexGrow
                      onClick={(_) => {
                        if (toggledClipForPlay == clip) {
                          renderPlayer([clip]);
                          setToggledClipForPlay(null);
                        } else {
                          setToggledClipForPlay(clip);
                        }
                      }}
                    >
                      {clipContext}
                    </Container>
                    <LinkPopover
                      placement="left"
                      renderLink={(setShow, target) => (
                        <Container ref={target}>
                          <IconButton
                            icon={<Stickies />}
                            hoverColor={theme.colors.light.highlight}
                            tooltip={'Add notes'}
                            onClick={() => setShow(true)}
                          />
                          {notesForClip?.length > 0 && (
                            <NotificationIndicator top={-6} right={-2}>
                              {notesForClip.length}
                            </NotificationIndicator>
                          )}
                        </Container>
                      )}
                      renderContent={(forceClose) => (
                        <Container width={500}>
                          <CompactNotes
                            defaultAddNote
                            entity={{
                              entityId: playlist.id,
                              entityType: 'BDD_PLAYLIST',
                              subEntityId: clip.id,
                              subEntityType: 'BDD_PLAYLIST_CLIP',
                            }}
                            onCancel={forceClose}
                          />
                        </Container>
                      )}
                    />
                    {playlist.canEdit && (
                      <>
                        <LinkPopover
                          placement="left"
                          title="Edit clip"
                          renderLink={(setShow, target) => (
                            <Container ref={target}>
                              <IconButton
                                icon={
                                  clipTouched ? (
                                    <FileEarmarkPlayFill />
                                  ) : (
                                    <FileEarmarkPlay />
                                  )
                                }
                                hoverColor={theme.colors.light.highlight}
                                tooltip={'Edit clip info'}
                                onClick={() => setShow(true)}
                              />
                            </Container>
                          )}
                          renderContent={(forceClose) => (
                            <Container width={500}>
                              <EditPlaylistClipForm
                                playlist={playlist}
                                clip={clip}
                                onSubmit={forceClose}
                              />
                            </Container>
                          )}
                        />
                        <IconButton
                          icon={<XLg />}
                          hoverColor={theme.colors.light.highlight}
                          tooltip={'Remove clip'}
                          disabled={loading}
                          onClick={() => removeClipFromPlaylist(clip)}
                        />
                      </>
                    )}
                  </Row>
                  {showNotes && (
                    <Container
                      width={'100%'}
                      paddingTop={1}
                      paddingLeft={8}
                      paddingRight={24}
                      paddingBottom={1}
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                      }}
                    >
                      <Column gap={1}>
                        {notesForClip.map((n) => (
                          <Border key={n.note.id}>{n.render()}</Border>
                        ))}
                      </Column>
                    </Container>
                  )}
                </Container>
              );
            }}
            noDragPreview={false}
            onChange={reorderPlaylist}
          />
        </Container>
      </Column>
    </Container>
  );
};
