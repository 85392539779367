import { roundToX } from "./data"
import { formatMinutes, formatSeconds } from "./helpers"

const defaultOpts = {
  decimals: 2,
  trailingZeros: true,
  showPercent: false
}
export const formatBddStat = (value, statDefinition, opts={}) => {
  // Given value, statDefinition (from res.format.stat_definitions), return formatted value
  if (!statDefinition) return value
  
  opts = { ...defaultOpts, ...opts }
  if (statDefinition.is_time_stat) {
    if (statDefinition.time_unit === 'seconds') {
      return formatSeconds(value)
    } else {
      return formatMinutes(value)
    }
  }
  const sdHasDec = 'hasDecimals' in statDefinition ? statDefinition.hasDecimals : statDefinition.has_decimals
  const decimalPlaces = sdHasDec !== undefined ? 
    sdHasDec ? opts.decimals : 0
    : opts.decimals
  const val = roundToX(value, decimalPlaces, opts.trailingZeros)
  if (opts.showPercent && (statDefinition.isPercentage || statDefinition.is_percentage)) {
    return `${val}%`
  }
  return val
}