import { Column, Container, Row } from 'components/bdd/Layout';
import { Typography } from 'components/bdd/Typography';
import { PlayFill } from 'react-bootstrap-icons';
import { IconButton } from 'components/bdd/Button';
import styled from 'styled-components';
import { theme } from 'constants';
import { formatSeconds } from 'helpers/helpers';

const SelectedContainer = styled(Container)({
  borderBottom: (props) =>
    !props.showBorder ? 'none' : props.selected ? `1px solid ${theme.colors.teams.bos.primary}` : '1px solid #333',
  boxShadow: (props) =>
    props.selected ? `inset 1px 1px 5px ${theme.colors.teams.bos.primary}` : null,
});

export const ShiftList = ({ shifts, isSelected, onSelected }) => {
  return (
    <Container>
      {shifts?.map((s) => (
        <Shift
          key={`s-${s.id}`}
          shift={s}
          isSelected={isSelected}
          onSelected={onSelected}
        />
      ))}
    </Container>
  );
};

export const getShiftLabel = (shift, name, description) =>
  shift && (
    <Row columnGap={1}>
      <Typography variant="body1">{shift.manpowerSituationIn} Shift</Typography>
      <Typography variant="body1">{formatSeconds(shift.duration)}</Typography>
      <Typography variant="body2">|</Typography>
      <Typography variant="body2">
        {shift.player.firstname} {shift.player.lastname}
      </Typography>
      {!!name || !!description ? (
        <Typography variant="body2">
          <b>{name}</b> {description && `| ${description}`}
        </Typography>
      ) : (
        <>
          {!!shift.isFaceoff && (
            <>
              <Typography variant="body2">|</Typography>
              <Typography variant="body2">Faceoff</Typography>
            </>
          )}
          {!!shift.zoneIn && (
            <>
              <Typography variant="body2">|</Typography>
              <Typography variant="body2">{shift.zoneIn.toUpperCase()}</Typography>
            </>
          )}
        </>
      )}
    </Row>
  );

export const Shift = ({ shift, number, name, description, isSelected, onSelected, showBorder=true }) => {
  const label = getShiftLabel(shift);
  const shiftName = name ? (
    <Typography variant="body2">
      <b>{name}</b> {description && `| ${description}`}
    </Typography>
  ) : (
    <Typography variant="body2">{label}</Typography>
  );

  return (
    <SelectedContainer
      padding={1}
      selected={isSelected(shift)}
      variant="button"
      onClick={() => onSelected && onSelected(shift)}
      showBorder={showBorder}
    >
      <Row columnGap={2}>
        <Column>
          <Row gap={2}>
            {!!number && <Typography variant='body1'>{number}</Typography>}
            {shiftName}
          </Row>
          <Row columnGap={1}>
            <Typography variant="stat">
              <b>{shift.formattedTimeIn}</b> vs. {shift.oppTeam.shorthand}
            </Typography>
          </Row>
          {/* <Divider style={{ paddingTop: theme.spacing[2] }} /> */}
        </Column>
      </Row>
    </SelectedContainer>
  );
};
