import { Column, Container, Row } from "components/bdd/Layout";
import { useCreateUpdateBFTrade } from "./useCreateUpdateBFTrade"
import { Label } from "components/bdd/Label/Label";
import { useEffect, useState } from "react";
import { tradeAssetsToPicks } from "../BDDTrades/helpers";
import { Typography } from "components/bdd/Typography";
import { Button } from "react-bootstrap";
import { BFTradePickUpdates } from "./BFTradePickUpdates";
import { BFTradeForm } from "./BFTradeForm";
import { toastInfo } from "components/bdd/bddtoasts";
import { BFTradeDepthChartUpdates } from "./BFTradeDepthChartUpdates";


export const CreateUpdateBFTradeFlow = ({
  existingBfTradeId,
  defaultTeams, // ['nhl.bos', 'nhl.ana']
  defaultAssets, // [{ fromTeamSlug, toTeamSlug, assetType, assetId }]
  defaultDate,
  transactionId, // if creating this through a transaction, can link to it to mark it as completed
  includeDepthUpdateStep=true,
  includePickUpdateStep=true,
  onCancel,
  onSubmit
}) => {
  const [state, setState] = useState({ step: 'trade', createdTrade: null });
  const isUpdate = !!existingBfTradeId;

  useEffect(() => {
    if (state.step === 'picks' && state.createdTrade?.tradedDraftPicks?.length === 0) {
      !!onSubmit && onSubmit();
    }
  }, [state])

  const handleSubmitTrade = (bfTrade) => {
    toastInfo(`BF Trade ${isUpdate ? 'updated' : 'created'}!`)
    if (includeDepthUpdateStep) {
      setState({...state, step: 'depth', createdTrade: bfTrade});
    } else if (includePickUpdateStep) {
      setState({...state, step: 'picks', createdTrade: bfTrade});
    } else {
      onSubmit && onSubmit();
    }
  }

  const hasSteps = includeDepthUpdateStep || includePickUpdateStep;

  const handleCancel = () => {
    if (!!onCancel) onCancel();
    else {
      setState({ step: 'trade', createdTrade: null });
    }
  }

  const handleFinish = () => {
    if (!!onSubmit) onSubmit();
    else {
      setState({ step: 'trade', createdTrade: null });
    }
  }

  return (
    <Container>
      <Row justifyContent='center'>
        <Container minWidth={400}>
          {state.step === 'trade'
            ? (
              <Column gap={4}>
                {hasSteps && <Typography variant='h6'>Step 1: {isUpdate ? 'Update' : 'Create'} Trade</Typography>}
                <BFTradeForm
                  existingBfTradeId={existingBfTradeId}
                  defaultTeams={defaultTeams}
                  defaultAssets={defaultAssets}
                  defaultDate={defaultDate}
                  transactionId={transactionId}
                  onCancel={onCancel}
                  onSubmit={handleSubmitTrade}
                /> 
              </Column>
            ) : state.step === 'depth' ? (
              <Column gap={4}>
                <Typography variant='h6'>Step 2: Update Depth Charts</Typography>
                <BFTradeDepthChartUpdates
                  bfTradeId={state.createdTrade.id}
                  onCompleted={() => {
                    if (includePickUpdateStep) {
                      setState({...state, step: 'picks'});
                    } else {
                      onSubmit && onSubmit();
                    }
                  }}
                />
              </Column>
            ) : (
              <Column gap={4}>
                <Typography variant='h6'>Step {includeDepthUpdateStep ? 3 : 2}: Update Pick Stocks</Typography>
                <BFTradePickUpdates 
                  bfTradeId={state.createdTrade.id} 
                  onCompleted={handleFinish}
                  onSkip={handleCancel}
                />
              </Column>
            )
          }
        </Container>
      </Row>

      {/* <Container paddingTop={100}>
        <button onClick={() => setState({step: 'picks', createdTrade: { id: 9 }})}>
          Skip
        </button>
      </Container> */}
    </Container>
  )
}
