import { removeNull } from 'helpers/data';
import produce from 'immer';
import React, { createContext, useEffect, useMemo, useState } from 'react';
import { VideoContextProvider } from '.';
import { useSearchParamsState } from '../hooks';
import { useStatDefinitionFilters } from './Filtering/hooks';
import { useEvents } from './hooks';

export const EventVideoContext = createContext();

export const EventVideoController = ({
  sourceFilters,
  eventFilters,
  slPlayer,
  statDefinitionSlug,
  secondaryPlayerFilters,
  sort,
  children,
  useStateParams,
  playlist,
  clipFilters,
}) => {
  const initialState = {
    sourceFilters,
    eventFilters,
    slPlayer,
    statDefinitionSlug,
    secondaryPlayerFilters,
    sort,
    error: null,
  };

  // Store data (in-memory or search params)
  const [store, setStore] = useStateParams
    ? useSearchParamsState(initialState, 'eventVideoContext')
    : useState(initialState);

  const { statDefinition, getStatDefinitionFilters } = useStatDefinitionFilters({
    slug: store.statDefinitionSlug,
  });

  const statDefinitionDefined = !!statDefinition;
  const statDefinitionFilters = useMemo(() => {
    if (!statDefinitionDefined || !store.sourceFilters) {
      return null;
    }

    let statDefinitionFilters;

    try {
      statDefinitionFilters = getStatDefinitionFilters(
        store.slPlayer,
        store.sourceFilters,
        store.secondaryPlayerFilters
      );
    } catch (e) {
      setStore((store) =>
        produce(store, (draft) => {
          draft.error = 'Could not define filters for stat definition';
        })
      );

      return;
    }

    return statDefinitionFilters.map((sd) =>
      removeNull({
        ...(sd || {}),
        ...(store.eventFilters || {}),
      })
    );
  }, [store, statDefinition]);

  useEffect(() => {
    if (!store.statDefinitionSlug && !store.sourceFilters) return;

    setStore((store) => ({
      ...store,
      filters: store.statDefinitionSlug ? statDefinitionFilters : [store.sourceFilters],
    }));
  }, [
    statDefinitionDefined,
    JSON.stringify(statDefinitionFilters),
    JSON.stringify(store.sourceFilters),
  ]);

  const { allEvents, loading, pageControls } = useEvents({
    filters: store.filters,
    sort: store.sort,
  });

  const setStatDefinition = (slug) =>
    setStore((store) =>
      produce(store, (draft) => {
        draft.statDefinitionSlug = slug;
      })
    );

  const setFilters = (filters, index) =>
    setStore((store) =>
      produce(store, (draft) => {
        draft.filters[index] = removeNull({
          ...draft.filters[index],
          ...filters,
        });
      })
    );

  const isFiltersDirty = (index) => {
    if (!statDefinitionDefined) return false;

    if (index) {
      return (
        JSON.stringify(statDefinitionFilters[index]) !=
        JSON.stringify(store.filters[index])
      );
    }

    return JSON.stringify(statDefinitionFilters) != JSON.stringify(store.filters);
  };

  const clearFilters = (index) =>
    setStore((store) =>
      produce(store, (draft) => {
        if (index) {
          draft.filters[index] = statDefinitionFilters[index];
        } else {
          draft.filters = statDefinitionFilters;
        }
      })
    );

  const setSourceFilters = (filters) =>
    setStore((store) =>
      produce(store, (draft) => {
        draft.sourceFilters = { ...draft.sourceFilters, ...filters };
      })
    );

  const replaceSourceFilters = (filters) =>
    setStore((store) =>
      produce(store, (draft) => {
        draft.sourceFilters = filters;
      })
    );

  const setSlPlayer = (slPlayer) =>
    setStore((store) =>
      produce(store, (draft) => {
        draft.slPlayer = slPlayer;
      })
    );

  const setSecondaryPlayerFilters = (filters) =>
    setStore((store) =>
      produce(store, (draft) => {
        draft.secondaryPlayerFilters = {
          ...draft.secondaryPlayerFilters,
          ...filters,
        };
      })
    );

  const setSort = (sort) =>
    setStore((store) =>
      produce(store, (draft) => {
        draft.sort = sort;
      })
    );

  useEffect(() => {
    if (!slPlayer || slPlayer.playerId == store.slPlayer.playerId) return;

    setSlPlayer(slPlayer);
  }, [slPlayer]);

  const context = {
    eventType: 'events',
    ...store,
    setSourceFilters,
    replaceSourceFilters,
    isFiltersDirty,
    setFilters,
    clearFilters,
    setSlPlayer,
    setStatDefinition,
    setSecondaryPlayerFilters,
    setSort,
    allEvents,
    loading,
    pageControls,
    playlist,
    clipFilters,
  };

  return <VideoContextProvider videoContext={context}>{children}</VideoContextProvider>;
};
