import React from 'react';
import styled from 'styled-components';
import { TooltipSpan } from '../reports';
import { theme } from 'constants';

const Styles = styled.div`
  background: ${(props) => props.background};
  color: ${(props) => props.color};
  ${(props) =>
    props.noBorder
      ? ''
      : props.isProjection
      ? `
        border: 1px dashed ${props.color};
        // box-shadow: 0 0 1px ${props.background};
        `
      : `border: 1px solid ${theme.colors.light.border};`}

  font-size: 10px;
  font-weight: bold;
  border-radius: 10px;
  padding: 3px;
  margin: 0px 2px;
  overflow: hidden;
  white-space: nowrap;
  padding-left: 8px;
  padding-right: 8px;
`;
export default function BDDTag({ tag, isProjection = false, noBorder = false }) {
  return (
    <TooltipSpan
      placement={'bottom'}
      content={
        <>
          {tag.description}
          {isProjection ? (
            <em>
              <small> (projected)</small>
            </em>
          ) : (
            ''
          )}
        </>
      }
    >
      <Styles
        background={
          isProjection
            ? `repeating-linear-gradient(
            -45deg,
            ${tag.color},
            ${tag.color} 5px,
            #eee 5px,
            #eee 6px
        )`
            : tag.color
        }
        color={tag.textColor || 'black'}
        noBorder={noBorder}
      >
        {tag.tag}
      </Styles>
    </TooltipSpan>
  );
}
