import React, { useContext } from 'react';
import { Paperclip } from 'react-bootstrap-icons';
import { IconButton } from 'components/bdd/Button';
import { FileUploadForm, ToolbarIconButton } from '.';
import { ModalContext, ModalContextProvider } from '../Modal';
import { Typography } from '../Typography';
import { Container } from '../Layout';

export const FileUploadButton = ({ folder, onSubmit, onCancel }) => {
  return (
    <ModalContextProvider>
      {({ renderModal, hideModal }) => {
        const form = (
          <Container padding={3}>
            <FileUploadForm
              folder={folder}
              onSubmit={(values) => {
                onSubmit(values);
                hideModal();
              }}
              onCancel={() => {
                onCancel && onCancel();
                hideModal();
              }}
            />
          </Container>
        );

        return (
          <ToolbarIconButton
            as={IconButton}
            icon={<Paperclip />}
            onClick={() => {
              renderModal({
                size: 'xl',
                title: <Typography variant="h6">Upload file</Typography>,
                body: form,
              });
            }}
          />
        );
      }}
    </ModalContextProvider>
  );
};
