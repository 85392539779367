import styled from 'styled-components';
import { Link, useHistory, withRouter } from 'react-router-dom';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { OverlayTrigger, Overlay, Popover, Modal, Row, Col, Card } from 'react-bootstrap';
import Expand from 'react-expand-animated';
import { bruinsGold } from '../../helpers/plotting';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faSearch } from '@fortawesome/free-solid-svg-icons';
import JSONInput from 'react-json-editor-ajrm';
import locale from 'react-json-editor-ajrm/locale/en';
import { SectionHeader, Unprinted } from '../reports';
import BDDErrorBoundary from './bdderrorboundary';
import { QuestionCircle, X } from 'react-bootstrap-icons';
import { Typography } from './Typography';
import { theme } from 'constants';
import { IconButton } from './Button';
import { ModalContext } from './Modal';
import { WindowContext } from './context';
import { Container } from './Layout';
import { Label } from './Label/Label';

export const BDDErrorRed = '#cc0000';

export const BDDJumbotron = styled.div`
  -webkit-box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.5);
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.92);
  margin: 1%;
  margin-bottom: 3%;
  margin-top: 3%;
  font-family: Arial Bold, sans-serif;
  padding: 10px;
`;


export const TabWithIcon = styled.div({
  display: 'inline-flex',
  alignItems: 'center',
  gap: theme.spacing[2],
});

export const StyledTab = styled(({ isActive, ...rest }) => <span {...rest} />)`
  cursor: pointer;
  ${(props) =>
    props.isActive
      ? `
  font-weight: bold;
  border-bottom: 5px solid ${bruinsGold};
  &:hover {
      opacity: 1;
      color: black;
      text-decoration: none;
  }
  `
      : 'font-weight: lighter;'}
`;

const StyledTabLink = styled(({ isActive, ...rest }) => <Link {...rest} />)`
  ${(props) =>
    props.isActive
      ? `
        font-weight: bold;
        border-bottom: 5px solid ${bruinsGold};
        &:hover {
            opacity: 1;
            color: black;
            text-decoration: none;
        }
    `
      : 'font-weight: lighter;'}
`;
// const BDDTabLink = (props) => <StyledTabLink {...props} />
const BDDTabLink = withRouter(
  ({
    useLinks = false, // if true, will use a link, so back button will go through tabs
    history,
    isactive,
    useHash,
    matchStart,
    matchSearch,
    matchSearchStart,
    location,
    to,
    children,
    onClick,
    onHistoryChange,
    style = {},
  }) => {
    if (!useLinks && !onClick) {
      onClick = (ev) => {
        if (ev.metaKey) {
          window.open(to, '_blank');
        } else {
          onHistoryChange && onHistoryChange(history, to);
          history.replace(to);
        }
      };
    }
    const disableLinkBehavior = !!onClick;

    if (isactive != undefined) {
      // no op
    } else if (useHash) {
      if (matchStart) isactive = location.hash.startsWith(to);
      else if (matchSearchStart)
        isactive =
          location.hash.startsWith(to) ||
          (location.search + location.hash).startsWith(to);
      else isactive = location.hash === to;
    } else if (matchStart) isactive = location.pathname.startsWith(to);
    else if (matchSearchStart) {
      isactive =
        location.pathname.startsWith(to) ||
        to.startsWith(location.pathname) ||
        (location.pathname + location.search).startsWith(to);
    } else if (matchSearch) {
      isactive = to.startsWith(location.pathname + location.search);
    } else {
      isactive = location.pathname === to;
    }
    return (
      <span style={{ display: 'inline-block' }}>
        <StyledTabLink
          className="tab"
          to={to}
          isActive={isactive}
          style={style}
          onClick={(ev) => {
            if (disableLinkBehavior) {
              ev.preventDefault();
              if (onClick) onClick(ev);
            }
          }}
        >
          {children}
        </StyledTabLink>
      </span>
    );
  }
);
export { BDDTabLink };

const TabLinkContainer = styled.div`
  ${(props) => (props.leftAlign ? 'padding: 5px 0px 5px 0px;' : 'padding-top: 5px;')}
  width: 100%;
  ${(props) => (props.border || props.topBorder ? 'border-top: 1px solid #ddd;' : null)}
  ${(props) =>
    props.border || props.bottomBorder ? 'border-bottom: 1px solid #ddd;' : null}

    ${(props) =>
    !!props.bottomMargin ? `margin-bottom: ${props.bottomMargin}px;` : null}
    line-height: 28px;
  .tab {
    color: black;
    ${(props) => (props.leftAlign ? null : 'display: table-cell;')}

    margin: 5px;
    margin-right: 15px; // space between tabs
    padding-bottom: 0px; // space between title and underline

    font-family: Oswald;

    &:focus,
    &:visited {
      color: black;
    }
    &:hover {
      opacity: 0.7;
      color: black;
      text-decoration: none;
    }

    ${(props) => (props.noUppercase ? null : 'text-transform: uppercase;')}
  }

  ${(props) =>
    props.leftAlign
      ? null
      : `
        text-align: center;
        display: table;
        table-layout: fixed;
    `}
`;
export const BDDTabLinkContainer = ({ style, leftAlign = true, ...props }) => (
  <>
    <TabLinkContainer leftAlign={leftAlign} {...props} style={style}>
      {props.children}
    </TabLinkContainer>
    {/* {props.nohr ? null : <hr></hr> } */}
  </>
);

export const OverflowEllipsisCol = styled(Col)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
export const OverflowEllipsisSpan = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const ItemGrid = ({
  items,
  numCols = 3,
  render,
  keys,
  keyField,
  leftToRight = true,
}) => {
  if (!keys && !keyField) keyField = 'id';
  var keyIdx = 0;
  if (!items.length) return null;
  if (leftToRight) {
    const rows = [...Array(Math.ceil(items.length / numCols))];
    const itemRows = rows.map((row, idx) =>
      items.slice(idx * numCols, idx * numCols + numCols)
    );
    var i = 0;
    return (
      <>
        {itemRows.map((row, idx) => (
          <Row key={idx}>
            {row.map((item, j) => {
              const key = keyField ? item[keyField] : keys ? keys[keyIdx++] : null;
              return (
                <Col key={key} md={parseInt(12 / numCols)}>
                  {render(item, i++)}
                </Col>
              );
            })}
          </Row>
        ))}
      </>
    );
  } else {
    const cols = [...Array(numCols)];
    const height = Math.ceil(items.length / numCols); // num rows in col
    const itemCols = cols.map((col, idx) =>
      items.slice(idx * height, (idx + 1) * height)
    );
    var i = 0;
    return (
      <Row>
        {itemCols.map((col, idx) => (
          <Col key={idx}>
            {col.map((item, j) => {
              const key = keyField ? item[keyField] : keys ? keys[keyIdx++] : null;
              return <div key={key}>{render(item, i++)}</div>;
            })}
          </Col>
        ))}
      </Row>
    );
  }
};

export const CardItem = ({ main, expand, defaultExpanded = false }) => {
  const [expanded, setExpanded] = useState(defaultExpanded);

  return (
    <Card style={{ padding: '10px' }}>
      {main}
      {!!expand ? (
        <>
          <em>
            <small>
              <a style={{ cursor: 'pointer' }} onClick={() => setExpanded(!expanded)}>
                {expanded ? 'Hide' : 'Show'} Details
              </a>
            </small>
          </em>
          <Expand open={expanded}>
            {typeof expand === 'function' ? expand(expanded) : expand}
          </Expand>
        </>
      ) : null}
    </Card>
  );
};

export function ExpandableImage(props) {
  const [show, setShow] = useState(false);
  return (
    <>
      <img
        style={{ cursor: 'pointer', ...props.style }}
        src={props.src}
        onClick={() => setShow(true)}
      />
      <Modal show={show} onHide={() => setShow(false)} size="lg">
        <Modal.Header closeButton></Modal.Header>
        <center>
          <img src={props.src} style={{ width: '90%', height: 'auto' }} />
        </center>
      </Modal>
    </>
  );
}

export const MinToiInput = styled.input`
  border: 0px;
  border-bottom: ;
  width: 50px;
  text-align: center;
  border-bottom: ${(props) => (props.isInvalid ? ' 1px solid red' : '1px solid #333')};

  :focus {
    ${(props) =>
      props.isInvalid
        ? `box-shadow: 0 0 5px ${BDDErrorRed};
            border: 1px solid ${BDDErrorRed};`
        : null}
  }
`;

export const MinToiField = ({
  defaultValue,
  minValue,
  onBlur,
  onChange,
  label = 'Min Toi:',
  type = 'text',
  forceInt = false,
}) => {
  const [value, setValue] = useState(defaultValue);
  const [lastValidValue, setLastValidValue] = useState(defaultValue);
  const [error, setError] = useState(null);
  if (!!onChange && !onBlur) {
    onBlur = onChange;
  }

  const handleChange = (ev) => {
    if (!!onChange) {
      // if onChange passed in
      const val = forceInt ? parseInt(ev.target.value) : parseFloat(ev.target.value);
      if (isNaN(val)) {
        setValue(0);
        setError('Number required');
      } else if (!!minValue && val < minValue) {
        setError(`Must be >${minValue}`);
      } else {
        // Valid value!
        setLastValidValue(val);
        setValue(val);
        setError(null);
        onChange(val);
      }
    } else {
      setValue(ev.target.value);
    }
  };
  return (
    <Row justify='start' noGutters>
      <Col sm='auto'>
        <Typography variant='body1'>
          {label}
        </Typography>
      </Col>
      <Col sm='auto'>
        <MinToiInput
          type={type}
          value={value}
          onChange={handleChange}
          isInvalid={!!error}
          onBlur={(ev) => {
            const val = forceInt ? parseInt(ev.target.value) : parseFloat(ev.target.value);
            if (ev.target.value.length == 0) {
              setValue(lastValidValue);
            } else if (isNaN(val)) {
              setValue(lastValidValue);
              setError('Number required');
            } else if (!!minValue && val < minValue) {
              setValue(lastValidValue);
              setError(`Must be >${minValue}`);
            } else {
              // Valid value!
              setLastValidValue(val);
              setValue(val);
              setError(null);
              onBlur(val);
            }
          }}
        />
      </Col>
      <BDDFormError>{error}</BDDFormError>
    </Row>
  );
};

export const BDDFormError = styled.span`
  font-size: 0.8em;
  color: ${BDDErrorRed};
  margin-top: 0px;
`;

const SmallSelect = styled.select`
  width: 100%;
  font-family: Oswald;
  border: 0px;
  border-bottom: 1px solid #ddd;
  text-align: center;
`;
export const BDDSelectSmall = ({ name, value, onChange, options, ...rest }) => {
  return (
    <SmallSelect
      value={value}
      onChange={(ev) => onChange(name, ev.target.value)}
      {...rest}
    >
      {options.map((o) => (
        <option key={o.value} value={o.value}>
          {o.label}
        </option>
      ))}
    </SmallSelect>
  );
};

export const HoverInfo = ({
  title,
  content,
  children,
  placement = 'auto',
  width,
  trigger,
  disabled = false,
}) => {
  if (disabled || (!content && !title)) return children;

  return (
    <OverlayTrigger
      trigger={trigger ? trigger : ['hover', 'focus']}
      placement={placement}
      flip={placement === 'auto'}
      overlay={(props) => (
        <Popover
          {...props}
          id={`popover-hoverinfo-${title || parseInt(Math.random() * 100)}`}
        >
          {title && (
            <Popover.Title as="h4">
              <Typography variant="body1">{title}</Typography>
            </Popover.Title>
          )}
          <Popover.Content>
            <Typography variant="body2">
              {typeof content === 'function' ? content() : content}
            </Typography>
          </Popover.Content>
        </Popover>
      )}
    >
      <span style={{ cursor: 'pointer' }}>
        {children != undefined && children != null ? (
          children
        ) : (
          <Unprinted>
            <QuestionCircle />
            {/* <FontAwesomeIcon icon={faQuestionCircle}/> */}
          </Unprinted>
        )}
      </span>
    </OverlayTrigger>
  );
};

export const HoverModalWrapper = ({ renderContent, ...props }) => {};

export const HoverInteractive = ({
  title,
  content,
  children,
  placement = 'auto',
  renderContent,
  style,
  delay = { show: 100, hide: 200 },
  disabled = false,
  closeButton = false,
  preventMouseLeave = false,
  renderAsFullScreenMobile,
}) => {
  const [show, setShow] = useState(false);
  const timer = useRef(null);
  const target = useRef(null);

  const { renderModal, hideModal } = useContext(ModalContext);
  const { width } = useContext(WindowContext);

  if (!content && !renderContent) throw Error('Must supply "content" or "renderContent"');
  const handleMouseEnter = () => {
    if (!!timer.current && !timer.current.destroyed) {
      clearTimeout(timer.current);
    }
    timer.current = setTimeout(() => setShow(true), delay.show);
  };
  const handleMouseLeave = () => {
    if (!!timer.current && !timer.current.destroyed) {
      clearTimeout(timer.current);
    }
    timer.current = setTimeout(() => setShow(false), delay.hide);
  };

  useEffect(() => {
    return () => clearTimeout(timer.current);
  }, []);

  useEffect(() => {
    if (show && renderAsFullScreenMobile && width <= theme.breakpoints.xs) {
      renderModal({
        title: <div />,
        body: (
          <Container padding={3}>
            {content ||
              renderContent(() => {
                forceClose();
                hideModal();
              })}
          </Container>
        ),
      });
    }
  }, [show]);

  if (!content && !title && !renderContent) return children;
  const forceClose = () => handleMouseLeave();
  return (
    <>
      <span
        ref={target}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={!preventMouseLeave ? handleMouseLeave : null}
        style={{ cursor: 'pointer', ...style }}
      >
        {children}
      </span>
      {(!renderAsFullScreenMobile || width > theme.breakpoints.xs) && (
        <Overlay
          show={show && !disabled}
          onHide={() => setShow(false)}
          placement={placement}
          flip={placement === 'auto'}
          target={target.current}
          trigger={['hover', 'focus']}
          rootClose={preventMouseLeave}
        >
          {(props) => (
            <Popover
              {...props}
              style={{ maxWidth: '100%', boxShadow: '0 0 5px #ddd', ...props.style }}
              id={`popover-hoverinfo-${title || parseInt(Math.random() * 100)}`}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={!preventMouseLeave ? handleMouseLeave : null}
            >
              {(title || closeButton) && (
                <Popover.Title as="h4">
                  <Row>
                    <Col>{title}</Col>
                    <Col sm="auto">
                      <div style={{ float: 'right' }}>
                        <IconButton
                          icon={<X />}
                          onClick={forceClose}
                          style={{ float: 'right' }}
                        />
                      </div>
                    </Col>
                  </Row>
                </Popover.Title>
              )}
              <Popover.Content>
                <BDDErrorBoundary>
                  {renderContent
                    ? renderContent(forceClose)
                    : typeof content === 'function'
                    ? content()
                    : content}
                </BDDErrorBoundary>
              </Popover.Content>
            </Popover>
          )}
        </Overlay>
      )}
    </>
  );
};

const IconStyles = styled.div`
  width: ${(props) => props.width || '100%'};
  ${(props) => (props.color ? `color: ${props.color};` : null)}
`;
export const BDDIcon = ({ icon, width, color, ...props }) => {
  return (
    <IconStyles className="bdd-icon" color={color} style={{ ...props }} width={width}>
      {icon()}
    </IconStyles>
  );
};

export const PillTabContainer = styled(Row)`
  border: 2px solid #555;
  border-radius: 5px;
  display: table;
`;
// const StyledTabLink = styled(({ isactive, ...rest }) => <Link {...rest} />)`
export const PillTab = styled(({ isactive, ...rest }) => <Col {...rest} />)`
  text-align: center;
  cursor: pointer;
  background: #fff;
  color: black;
  border-right: 1px solid #ddd;
  ${(props) =>
    props.isactive
      ? `
        background: #555;
        color: white;
    `
      : `
        :hover {
            background: #DDD;
        }
    `}
`;

export const LinkUnstyled = styled(({ noHover, ...rest }) => <Link {...rest} />)`
  color: inherit;
  ${(props) =>
    props.noHover
      ? `
    :hover { 
        color: inherit;
        text-decoration: none; 
    }`
      : null}
`;

const ClickableIconStyles = styled.span`
  border-radius: 50%;
  padding: 1%;
  cursor: pointer;
  :hover {
    background-color: ${(props) =>
      !!props.backgroundColor ? props.backgroundColor : bruinsGold};
    background-opacity: 0.4;
  }
  transition: all 0.3s ease-in-out;
`;
export const ClickableIcon = ({ icon, backgroundColor, ...rest }) => (
  <ClickableIconStyles backgroundColor={backgroundColor} {...rest}>
    <FontAwesomeIcon icon={icon} />
  </ClickableIconStyles>
);

const StyledTextArea = styled.textarea(({ isInvalid }) => ({
  ...theme.typography.body2,
  width: '100%',
  borderRadius: '5px',
  border: '1px solid #bababa',
  padding: '5px',
  ...(isInvalid && {
    boxShadow: '0 0 5px red',
    border: '1px solid red',
  }),
}));

export const BDDTextArea = ({ autoFocus, ...props }) => {
  const ref = useRef(null);
  if (autoFocus) {
    useEffect(() => {
      if (!!ref) ref.current.focus();
    }, [ref]);
  }
  return <StyledTextArea ref={ref} {...props} />;
};

export const BDDJSON = ({ onChange, ...rest }) => (
  <JSONInput
    id="json-input-id"
    locale={locale}
    height="150px"
    width="100%"
    waitAfterKeyPress={2000}
    onBlur={onChange}
    {...rest}
  />
);

export const BlueDot = styled.div`
  min-height: 10px;
  min-width: 10px;
  border-radius: 50%;
  background: #4574eb;
`;

const BDDOptionGroupContainerStyles = styled.div``;
export const BDDOptionGroup = ({
  isMulti,
  options,
  selected,
  onClick,
  style,
  fontSize,
}) => {
  // if (isMulti && !Array.isArray(selected)) console.error(`Expected "selected" to be of type Array when isMulti set`)
  return (
    <BDDOptionGroupContainerStyles fontSize="12pt" style={style}>
      {options.map((opt) => {
        var active = false;
        if (!selected) {
          active = false;
        } else if (Array.isArray(selected)) {
          active = selected.includes(opt.value);
        } else {
          active = selected === opt.value;
        }
        return (
          <BDDOptionGroupOption
            key={opt.value}
            fontSize={fontSize}
            active={active}
            onClick={() => onClick(opt.value)}
          >
            {opt.label}
          </BDDOptionGroupOption>
        );
      })}
    </BDDOptionGroupContainerStyles>
  );
};
const BDDOptionGroupOptionStyles = styled.span`
  margin: 10px;
  cursor: pointer;
  font-family: Oswald;
  font-size: ${(props) => props.fontSize || '12pt'};
  ${(props) =>
    props.active &&
    `
        font-weight: bold;
        border-bottom: 3px solid ${bruinsGold};
    `}
  :hover {
    color: #ddd;
  }
`;
const BDDOptionGroupOption = ({ children, active, onClick, fontSize }) => {
  return (
    <BDDOptionGroupOptionStyles fontSize={fontSize} active={active} onClick={onClick}>
      {children}
    </BDDOptionGroupOptionStyles>
  );
};

export const BDDTitleAndBackArrow = ({ title, backRoute, showBackArrow = true }) => {
  const history = useHistory();
  return (
    <div style={{ paddingLeft: '10px', paddingTop: '10px' }}>
      {showBackArrow ? (
        backRoute ? (
          <Link to={backRoute} style={{ color: 'black', minWidth: '40px' }}>
            <span style={{ float: 'left', cursor: 'pointer', fontSize: '20px' }}>
              <FontAwesomeIcon icon={faArrowLeft} />
            </span>
          </Link>
        ) : (
          <span
            style={{ float: 'left', cursor: 'pointer', fontSize: '20px' }}
            onClick={() => history.goBack()}
          >
            <FontAwesomeIcon icon={faArrowLeft} />
          </span>
        )
      ) : null}
      <SectionHeader>{title}</SectionHeader>
    </div>
  );
};

const SearchBarStyles = styled.div`
  margin: auto;
  width: ${(props) => props.width || '100%'};
  box-shadow: 0 0 5px #ddd;
  border-radius: 20px;
  padding: 5px 15px;
  font-size: 14pt;
  white-space: nowrap;

  .search-icon {
    padding-right: 10px;
    float: left;
    width: auto;
  }
  .bddsearchbar {
    overflow: hidden;
  }

  input {
    border: 0;
    width: 100%;
    outline: none;
    margin-right: 10px;
  }
`;
export const BDDSearchBar = ({ value, setValue, width = '100%', placeholder = '' }) => {
  return (
    <SearchBarStyles width={width}>
      <div>
        <div className="search-icon">
          <FontAwesomeIcon icon={faSearch} />
        </div>
        <div className="bddsearchbar">
          <input
            value={value}
            onChange={(ev) => setValue(ev.target.value)}
            placeholder={placeholder}
          />
        </div>
      </div>
    </SearchBarStyles>
  );
};
