import { TooltipSpan } from 'components/reports';
import { Border, Column, Container, Row } from '../Layout';
import { LinkPopover } from '../Popover';
import { Typography } from '../Typography';
import { Button } from 'react-bootstrap';
import Icon from '../Icon';
import { LayoutThreeColumns, X } from 'react-bootstrap-icons';
import { IconButton, LinkButton } from '../Button';
import { StyledBDDSelect } from '../Select/StyledBDDSelect';
import { DraggableList } from '../DragAndDrop';
import { useRef } from 'react';

export const ColumnDisplayButton = ({ ...columnProps }) => {
  return (
    <LinkPopover
      hideTransition
      placement="right"
      renderContent={() => (
        <Container maxWidth={300} onClick={(e) => e.stopPropagation()}>
          <ColumnDisplayResults {...columnProps} />
        </Container>
      )}
      renderLink={(setShow, target, show) => (
        <Container paddingLeft={1} ref={target}>
          <TooltipSpan
            content={<Typography variant="body2">Show/Hide columns</Typography>}
          >
            <Button variant="outline-dark" size="sm" onClick={() => setShow(!show)}>
              <Icon icon={<LayoutThreeColumns />} padding={2} />
            </Button>
          </TooltipSpan>
        </Container>
      )}
    />
  );
};

const ColumnDisplayResults = ({
  idToColumn,
  columnIdsToDisplay,
  setColumnIdsToDisplay,
}) => {
  const ref = useRef();

  return (
    <Container padding={2}>
      <Column gap={2}>
        {/* {!presetUsed && (
        <Row columnGap={1} justifyContent="space-between">
          <LinkButton
            onClick={() => {
              localStorage.setItem(columnIdKey, columnIdsToDisplay);
              setStoredColumnIds(columnIdsToDisplay);
            }}
          >
            <Typography variant="stat">Save as preset</Typography>
          </LinkButton>
          {storedColumnIdsToDisplay.length > 0 && (
            <LinkButton onClick={() => setColumnIdsToDisplay(storedColumnIdsToDisplay)}>
              <Typography variant="stat" color={theme.colors.states.danger}>
                Revert to saved preset
              </Typography>
            </LinkButton>
          )}
        </Row>
      )} */}
        <Container width={200}>
          <StyledBDDSelect
            size="sm"
            options={Object.values(idToColumn)
              .filter((c) => !columnIdsToDisplay.includes(c.id))
              .map((c) => ({ value: c.id, label: !!c.header ? c.header : c.Header }))}
            selectProps={{
              placeholder: 'Add column...',
              isSearchable: true,
            }}
            onChange={(n, v) => {
              setColumnIdsToDisplay([...columnIdsToDisplay, v]);
              ref.current.scrollIntoView({
                behavior: 'smooth',
                block: 'end',
              });
            }}
          />
        </Container>
        <Container maxHeight={300} overflow="auto">
          <DraggableList
            ids={columnIdsToDisplay.filter((id) => id in idToColumn)}
            renderItem={(id, index) => {
              const column = idToColumn[id];
              if (!column) {
                return null;
              }

              return (
                <Border>
                  <Container padding={0.5}>
                    <Row columnGap={1} justifyContent="space-between">
                      <Typography variant="label">{!!column.header ? column.header : column.Header}</Typography>
                      <IconButton
                        icon={<X />}
                        onClick={() =>
                          setColumnIdsToDisplay(
                            columnIdsToDisplay.filter((id) => id != column.id)
                          )
                        }
                      />
                    </Row>
                  </Container>
                </Border>
              );
            }}
            noDragPreview={false}
            onChange={({ ids }) => {
              setColumnIdsToDisplay(ids);
            }}
          />
          <div style={{ height: 45 }} ref={ref} />
        </Container>
      </Column>
    </Container>
  );
};
