import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { theme } from 'constants';
import { numberToOrdinal } from 'helpers/helpers';
import { buildTeamRoute, buildPlayerListRoute } from 'helpers/routing';
import { TooltipSpan } from 'components/reports';

import PlayerHeadshot from '../PlayerHeadshot';
import PlayerContract from '../playercontract';
import { Column, Container, Grid, Row } from 'components/bdd/Layout';
import { Typography } from 'components/bdd/Typography';
import { EPLink } from 'components/bdd/lineupcomponents';
import { BFPlayerContracts } from '../Contract/BFPlayerContracts';
import { PlayerTags } from 'components/Lists/Builder/components/PlayerTags';
import { useUser } from 'helpers/user';
import { PlayerHeightWeight } from '../PlayerSize/PlayerHeightWeight';
import { LinkButton } from 'components/bdd/Button';
import Icon from 'components/bdd/Icon';
import { Clipboard } from 'react-bootstrap-icons';
import { toastInfo } from 'components/bdd/bddtoasts';

const Divider = styled.div({
  ...theme.borders.thin,
  ...theme.borders.top,
  ...theme.borders.light,
  width: '100%',
});

const TooltipFieldLabel = styled.div({
  ...theme.typography.body2,
  color: theme.colors.dark.text.primary,
});

const Field = ({ title, value, tooltip }) => (
  <Container>
    <Row columnGap={1} flexWrap>
      <Typography variant="body1">{title}</Typography>
      <TooltipSpan content={tooltip}>
        <Typography variant="body2">{value}</Typography>
      </TooltipSpan>
    </Row>
  </Container>
);


const TooltipToClip = ({ label, children, value }) => (
  <TooltipSpan 
    content={(
      <Row gap={4}>
        <Icon icon={<Clipboard/>} />
        {value}
      </Row>
    )} 
    onClick={() => {
      navigator.clipboard.writeText(value)
      toastInfo('Copied to clipboard!')
    }}>
    {!!children && children}
    {!!label && <Typography variant="body2">{label}</Typography>}
  </TooltipSpan>
)

export const PlayerBio = ({
  bddPlayer,
  rinknetPlayer,
  nhlPlayerStats,
  excludeContractListRow,
}) => {
  const { isUserAdmin } = useUser();
  const playerLists =
    bddPlayer?.playerLists.filter(
      (pl) =>
        pl.playerList.listType !== 'PLAYER_DEVELOPMENT_LIST' &&
        pl.playerList.listType !== 'BDD_POLL_ANSWER' &&
        pl.playerList.isActive
    ) || [];

  const inNHL = rinknetPlayer?.currentteamnhlid;
  const DEFAULT_LISTS_TO_SHOW = 5;
  const [numListsToShow, setNumListsToShow] = useState(DEFAULT_LISTS_TO_SHOW);
 
  // filter to playerlists with valid route
  const filteredPlayerLists = playerLists.filter(
    (l) => !!buildPlayerListRoute(l.playerList.listType, l.playerList.id)
  );

  const pinnedLists = filteredPlayerLists.filter(p => !!p.playerList?.pinPriority).sort((a, b) => a.playerList?.pinPriority - b.playerList?.pinPriority);
  const unPinnedLists = filteredPlayerLists.filter(p => !p.playerList?.pinPriority);

  const renderPlayerList = (l) => (
    <Typography variant="body2" key={l.id}>
      #{l.listNumber} in{' '}
      <Link
        to={buildPlayerListRoute(l.playerList.listType, l.playerList.id)}
        title={l.playerList.name}
      >
        {l.playerList.name}
      </Link>
    </Typography>
  )

  return (
    <Container overflow="hidden">
      <Column gap={2}>
        <Row columnGap={4} flexWrap>
          <PlayerHeadshot
            nhlPlayerStats={nhlPlayerStats}
            // playerNhlId={rinknetPlayer.nhlid}
            // teamShorthand={rinknetPlayer.currentteamshorthand}
            // teamId={rinknetPlayer.currentteamnhlid}
          />
          <Column>
            <Row columnGap={2}>
              <Typography variant="h5">
                {rinknetPlayer.firstname} {rinknetPlayer.lastname} #
                {rinknetPlayer.jerseynumber}
              </Typography>
            </Row>
            <Typography variant="body2">
              {parseInt(rinknetPlayer.age)} y.o.{' '}
              <small>
                <b>({rinknetPlayer.birthdate})</b>
              </small>
            </Typography>
            <PlayerHeightWeight rinknetPlayer={rinknetPlayer} />
            <Typography variant="body2">{rinknetPlayer.hometown}</Typography>
          </Column>
          {/* <div>
          <PlayerContract
            activeContract={bddPlayer?.activeContract}
            futureContract={bddPlayer?.futureContract}
            allContracts={bddPlayer?.nhlContracts}
            isLoading={false}
            orientation="vertical"
          />
        </div> */}
        </Row>
        <Divider />
        <Grid gridTemplateColumns="1fr 1fr">
          <Column>
            <Field
              title="Team:"
              value={
                inNHL ? (
                  <a href={buildTeamRoute(rinknetPlayer.currentteamslug)}>
                    {rinknetPlayer.currentteam}
                  </a>
                ) : (
                  rinknetPlayer.currentteam
                )
              }
            />
            <Field title="Position:" value={rinknetPlayer.position} />
            <Field title='Hometown:' value={rinknetPlayer.hometown || rinknetPlayer.nationality} tooltip={rinknetPlayer.nationality} />
            {!!rinknetPlayer.agent && (
              <Field
                title="Agent:"
                value={
                  <TooltipSpan
                    content={
                      <TooltipFieldLabel>
                        <div>
                          <b>Cell:</b> {rinknetPlayer.agent.cellphone}
                        </div>
                        <div>
                          <b>Phone:</b> {rinknetPlayer.agent.phone}
                        </div>
                        <div>
                          <b>Email:</b> {rinknetPlayer.agent.email}
                        </div>
                        <div>
                          <b>Company:</b> {rinknetPlayer.agent.companyname}
                        </div>
                      </TooltipFieldLabel>
                    }
                  >
                    {rinknetPlayer.agent.title} {rinknetPlayer.agent.firstname}{' '}
                    {rinknetPlayer.agent.lastname}
                  </TooltipSpan>
                }
              />
            )}
            {!!rinknetPlayer.collegecommitmentteam && (
              <Field
                title="College Commitment:"
                value={rinknetPlayer.collegecommitmentteam}
              />
            )}
          </Column>
          <Column>
            <Field title="League:" value={rinknetPlayer.currentleagueabbr} tooltip={rinknetPlayer.currentleague} />
            <Field title="Shoots/Catches:" value={rinknetPlayer.handedness} />
            <Field
              title="Draft:"
              value={
                rinknetPlayer.overall ? (
                  <>
                    {'R' + rinknetPlayer.round}/
                    {numberToOrdinal(rinknetPlayer.overall)}/
                    <Link
                      to={buildTeamRoute(rinknetPlayer.selectedbyteamslug)}
                      style={{ color: 'black' }}
                    >
                      {rinknetPlayer.selectedbyteamshort}
                    </Link>
                    /{rinknetPlayer.draftyear}
                  </>
                ) : (
                  <em>No draft info</em>
                )
              }
            />
            <Field
              title="Links:" value={
                  <Typography variant="body2">
                    <Row columnGap={1} flexWrap>
                      <TooltipToClip label="NHL" value={bddPlayer.nhlid}/>
                      <div>|</div> 
                      <TooltipToClip label="BDD" value={bddPlayer.slug}/>
                      <div>|</div> 
                      <TooltipToClip label="RN" value={bddPlayer.rinknetId}/>
                      <div>|</div> 
                      <TooltipToClip label="SL" value={bddPlayer.slUid}/>
                      <div>|</div> 
                      <TooltipToClip label="BF" value={bddPlayer.bfSlug}/>
                      <div>|</div> 
                      <TooltipToClip value={bddPlayer.epId}>
                        <EPLink
                          epid={bddPlayer.epId}
                          firstname={rinknetPlayer.firstname}
                          lastname={rinknetPlayer.lastname}
                        />
                      </TooltipToClip>
                    </Row>
                  </Typography>
              }
              />
          </Column>
        </Grid>
        <Divider />
        
        <PlayerTags
          bddPlayer={bddPlayer}
          allowEdit={isUserAdmin()}
        />
        {!excludeContractListRow && (
          <>
            <Divider/>

            <Grid gridTemplateColumns="0.5fr 1fr">
              <Column>
                <Typography variant="body1">Contract:</Typography>
                <BFPlayerContracts
                  bfPlayer={bddPlayer?.bfPlayer}
                  playerName={`${bddPlayer?.firstname} ${bddPlayer?.lastname}`}
                  // allContracts={bddPlayer?.bfAllContracts}
                  bfCapHit={bddPlayer?.bfCapHit}
                />
              </Column>
              <Container>

                <Container maxHeight={400} overflow='auto'>
                  <Column gap={1}>
                    {pinnedLists.length > 0 && (
                      <Column>
                        <Typography variant="body1">Pinned Lists ({pinnedLists.length}):</Typography>
                        {pinnedLists.map((l) => renderPlayerList(l))}
                        {/* {pinnedLists.length > numListsToShow && <Typography variant='stat'>{pinnedLists.length - numListsToShow} more...</Typography>} */}
                      </Column>
                    )}
                    {unPinnedLists.length > 0 && (
                      <Column>
                        <Typography variant="body1">All Lists ({unPinnedLists.length}):</Typography>
                        {unPinnedLists.slice(0, Math.max(0, numListsToShow - pinnedLists.length)).map((l) => renderPlayerList(l))}
                        {unPinnedLists.length > numListsToShow - pinnedLists.length && <Typography variant='stat'>{unPinnedLists.length - (numListsToShow - pinnedLists.length)} more...</Typography>}
                      </Column>
                    )}
                    {!filteredPlayerLists.length && (
                      <Container>
                        <Typography variant='body1'>All Lists:</Typography>
                        <Typography variant="body2">No lists found</Typography>
                      </Container>
                    )}
                    {numListsToShow < filteredPlayerLists.length && (
                      <Typography variant="body2">
                        <LinkButton onClick={(e) => setNumListsToShow(playerLists.length)}>
                          Show {filteredPlayerLists.length - numListsToShow} more...
                        </LinkButton>
                      </Typography>
                    )}
                  </Column>
                </Container>
                {numListsToShow > DEFAULT_LISTS_TO_SHOW && (
                  <LinkButton onClick={(e) => setNumListsToShow(DEFAULT_LISTS_TO_SHOW)}>
                    <Typography variant="body1">
                      Hide lists...
                    </Typography>
                  </LinkButton>
                )}
              </Container>
            </Grid>
          </>
        )}
      </Column>
    </Container>
  );
};
