import React, { useMemo, useState } from 'react';
import { useQuery } from '@apollo/client';
import { GET_PLAYER_LINEUP_SPOTS } from 'apollo/queries/lineup.queries';
import { HoverInteractive } from 'components/bdd';
import { formatDate } from 'helpers/helpers';
import { Col, Row } from 'react-bootstrap';
import styled from 'styled-components';
import { getCurrentSeason, positionToAbbrev } from '../../helpers/hockeyutils';
import BDDApiError from '../bdd/bddapierror';
import { BDDLoader } from '../bdd/bddloader';
import useLSGTOptions from './hooks/useLSGTOptions';
import { Column, Container } from 'components/bdd/Layout';
import CommonLines from './Deployment/CommonLines';
import BDDErrorBoundary from 'components/bdd/bdderrorboundary';

const LineupTable = styled.table({
  width: '100%',
  fontSize: '0.8em',
  textAlign: 'center',
  td: {
    padding: '0px 10px',
    borderBottom: '1px solid #ddd',
  },
  '.line-num': {
    fontSize: '0.6em',
    color: '#aaa',
    borderBottom: 0,
  },
});

const HoverContainer = styled.div({
  maxHeight: '300px',
  overflow: 'auto',
  paddingRight: '10px',
});

const LinematesTable = styled.table({
  fontSize: '8pt',
  th: {
    paddingRight: '10px',
    fontSize: '7pt',
  },
});

export default function PlayerLineupSpotsV3({
  bddPlayer,
  leagueSlug: _leagueSlug,
  leagueButtonGroupSm,
  posFDG,
}) {
  const {
    season,
    leagueSlug,
    game_type: gameType,
    loading: loadingLSGT,
    selectLSGT,
  } = useLSGTOptions({
    slug: bddPlayer.slug,
    variant: 'outlined',
    _leagueSlug,
    combineTeamSlugs: true,
    selectProps: {
      placeholder: '',
    },
  });

  const { data, loading, error } = useQuery(GET_PLAYER_LINEUP_SPOTS, {
    skip: !season || !gameType || !leagueSlug,
    variables: {
      slUid: bddPlayer.slUid,
      leagueSlug,
      season,
      gameType,
    },
  });

  const spotToCount = useMemo(() => {
    if (!data) return {};
    return data.slPlayerMaster?.lineupSpotsV3.reduce((acc, curr) => {
      acc[curr.lineupSpot] = !!acc.hasOwnProperty(curr.lineupSpot)
        ? {
            ...acc[curr.lineupSpot],
            games: acc[curr.lineupSpot].games.concat([curr.lineup.game]),
            linemates: acc[curr.lineupSpot].linemates.concat([curr.linemates]),
            count: acc[curr.lineupSpot].count + 1,
          }
        : {
            games: [curr.lineup.game],
            linemates: [curr.linemates],
            count: 1,
            lineupSpot: curr,
          };
      return acc;
    }, {});
  }, [data]);

  if (!!data) {
    const slPlayerMaster = data.slPlayerMaster;
    var lineupSpots = data.slPlayerMaster.lineupSpotsV3;
    const forwardSpots = lineupSpots.filter((ls) => ls.isF);
    const isForward = forwardSpots.length > lineupSpots.length / 2;

    var fPositions = ['leftwing', 'center', 'rightwing'];
    var dPositions = ['leftdefence', 'rightdefence'];
    var fPos2d = [
      [0, 0, 0],
      [0, 0, 0],
      [0, 0, 0],
      [0, 0, 0],
    ];
    var dPos2d = [
      [0, 0],
      [0, 0],
      [0, 0],
    ];

    Object.values(spotToCount).forEach((obj) => {
      const ls = obj.lineupSpot;
      const lineNum = ls.lineNum - 1;
      try {
        if (isForward) {
          fPos2d[lineNum][fPositions.indexOf(ls.position)] = obj.count;
        } else {
          dPos2d[lineNum][dPositions.indexOf(ls.position)] = obj.count;
        }
      } catch {}
    });
    const positions = isForward ? fPositions : dPositions;
    const posArr = isForward ? fPos2d : dPos2d;
    var positionTable = (
      <LineupTable>
        <tbody>
          <tr>
            <th></th>
            {positions.map((p) => (
              <th key={p}>{positionToAbbrev(p)}</th>
            ))}
          </tr>
          {posArr.map((arr, i) => (
            <tr key={i}>
              <td className="line-num">
                {isForward ? 'LINE' : 'PAIR'} {i + 1}
              </td>
              {posArr[i].map((count, j) => {
                const position = isForward ? fPositions[j] : dPositions[j];
                const lineupSpot = `${position}${i + 1}`;
                const obj = spotToCount[lineupSpot];
                const spotDef = { ...obj?.lineupSpot };
                spotDef['slPlayer'] = { lastname: slPlayerMaster.lastname };
                return (
                  <td key={j} style={count > 0 ? { fontWeight: 'bold' } : {}}>
                    <HoverInteractive
                      title={`${positionToAbbrev(spotDef?.position)} ${spotDef?.lineNum}`}
                      disabled={!spotToCount.hasOwnProperty(lineupSpot)}
                      content={() => (
                        <HoverContainer>
                          <LinematesTable>
                            <tbody>
                              {obj?.games.map((g, idx) => {
                                const linemates = obj.linemates[idx];
                                const home = g.homeTeamSlug.split('.')[1].toUpperCase();
                                const away = g.awayTeamSlug.split('.')[1].toUpperCase();
                                const positions = isForward ? fPositions : dPositions;
                                const players = [spotDef, ...linemates];
                                return (
                                  <tr key={g.id}>
                                    <th>
                                      <div>
                                        {formatDate(g.gameDate, {
                                          format: '%m/%d/%y',
                                        })}{' '}
                                        {away} ({g.awayScore}) @ {home} ({g.homeScore})
                                      </div>
                                    </th>
                                    <td>
                                      {positions
                                        .map((p) => {
                                          const plyr = players.find(
                                            (pi) => pi.position === p
                                          );

                                          return plyr?.slPlayer.lastname || 'UNLINKED';
                                        })
                                        .join(' - ')}
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </LinematesTable>
                        </HoverContainer>
                      )}
                    >
                      {count}
                    </HoverInteractive>
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </LineupTable>
    );
  }

  const commonLinemates = (
    <BDDErrorBoundary>
      <CommonLines
        bddPlayer={bddPlayer}
        leagueSlug={leagueSlug}
        season={season}
        gameType={gameType}
        posFDG={posFDG}
        defaultNumToShow={2}
      />
    </BDDErrorBoundary>
  );

  return (
    <Container>
      <Column gap={2}>
        <div
          style={{
            maxWidth: '200px',
            overflowX: 'auto',
          }}
        >
          {leagueButtonGroupSm}
        </div>
        {selectLSGT}
        {loading ? (
          <Col>
            <BDDLoader />
          </Col>
        ) : error ? (
          <BDDApiError error={error} />
        ) : (
          <Column gap={2}>
            <div>{positionTable}</div>
            <div>{commonLinemates}</div>
          </Column>
        )}
      </Column>
    </Container>
  );
}
