import React from 'react';
import { Container, Row } from '../Layout';
import { Typography } from '../Typography';
import { theme } from 'constants';
import { getFilterDisplayValue } from './helpers';

export const QueryTags = ({ filters, options }) => {
  return (
    <Container>
      <Row columnGap={1}>
        {filters?.map((f) => (
          <React.Fragment key={f.key}>
            <QueryTag filter={f} options={options} />
          </React.Fragment>
        ))}
      </Row>
    </Container>
  );
};

const QueryTag = ({ filter, options }) => {
  const filterOptions = options.find(
    (o) => o.col == filter.col || o.col == filter.parentCol
  );

  return (
    <Container
      key={filter.key}
      padding={1}
      backgroundColor={theme.colors.light.secondary}
    >
      <Row columnGap={1}>
        <Typography variant="label">{filter.label || filterOptions.label}</Typography>
        <Typography variant="stat">{filter.cond}</Typography>
        <Typography variant="stat">
          {getFilterDisplayValue(filter, filterOptions.type)}
        </Typography>
        {filter.extraFilters?.map((ef) => {
          const efOptions = filterOptions.extraFilters?.find((efo) => efo.col == ef.col);

          if (efOptions.type == 'custom' || efOptions.ignoreTag) return null;

          return (
            <React.Fragment key={ef.col}>
              <Container>
                <Row columnGap={1}>
                  <Typography variant="label">{efOptions.label}</Typography>
                  <Typography variant="stat">{ef.cond}</Typography>
                  <Typography variant="stat">
                    {efOptions.options
                      ? efOptions.options.find((o) => o.value == ef.val).label
                      : ef.val}
                  </Typography>
                </Row>
              </Container>
            </React.Fragment>
          );
        })}
      </Row>
    </Container>
  );
};
