import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { permissions } from 'constants'
import React, { useState } from 'react'
import { Modal, Row, Col, Button } from 'react-bootstrap'
import styled from 'styled-components'
import { useUser } from '../../helpers/user'
import BDDErrorBoundary from '../bdd/bdderrorboundary'

import { SectionHeader, SubSectionHeader } from '../reports'
import AddBDDTagForm from './addbddtagform'
import ManagePlayerTags from './manageplayertags'
import RequestPlayerTagForm from './requestplayertagform'

const Styles = styled.div`
    .backarrow {
        font-size: 16pt;
        cursor: pointer;
    }
    a {
        font-size: 0.8em;
    }
`
export default function ManagePlayerTagModal({
    show,
    handleClose,
    bddPlayerSlug,
    playerName,
    addTagUpdateCallback,
    removeTagUpdateCallback
}) {
    const { isUserAdmin, userHasPermission } = useUser();
    const [addTag, setAddTag] = useState(false)

    return <Modal
        show={show}
        onHide={handleClose}
    >
        <Modal.Header>
            <SectionHeader>{playerName || bddPlayerSlug} Player Tags</SectionHeader>
        </Modal.Header>
        <Modal.Body>
            <BDDErrorBoundary>
            <Styles>
            {userHasPermission(permissions.editActiveDepthCharts) ? <>
                {!!addTag ? <> 
                    <Row>
                        <Col sm='1' className='backarrow' onClick={() => setAddTag(false)}>
                            <FontAwesomeIcon icon={faArrowLeft}/>
                        </Col>
                        <Col>
                            <SubSectionHeader>Create New Tag</SubSectionHeader>
                        </Col>
                        <Col sm='1'></Col>
                    </Row>
                    <AddBDDTagForm onCompleted={() => setAddTag(false)}/>
                </> : <>
                    <ManagePlayerTags 
                      bddPlayerSlug={bddPlayerSlug} 
                      addTagUpdateCallback={addTagUpdateCallback}
                      removeTagUpdateCallback={removeTagUpdateCallback}
                    />
                    {/* {isUserAdmin() && <a href=""
                        onClick={ev => {
                            ev.preventDefault()
                            ev.stopPropagation()
                            setAddTag(true)
                        }}>
                        Create new tag...
                    </a>} */}
                </>}
            </> : <>
                <RequestPlayerTagForm bddPlayerSlug={bddPlayerSlug} />
            </> }
            </Styles>
            </BDDErrorBoundary>
        </Modal.Body>
        <Modal.Footer>
            {isUserAdmin() && 
                <Button disabled={addTag} variant='outline-primary' onClick={() => setAddTag(true)}>
                    Create New Tag
                </Button>
            }
            {' '}
            <Button variant='outline-dark' onClick={handleClose}>
                Done
            </Button>
        </Modal.Footer>
    </Modal>
}