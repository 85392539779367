import { gql } from '@apollo/client';
import { SLEventFragment } from './events.fragments';
import { SLShiftFragment } from './shifts.queries';

export const BDDVideoLinkFragment = gql`
  fragment bddVideoLinkFragment on BDDVideoLinks {
    id
    gameUid
    period
    videoUrl
    videoTimeS
    gameTimeS
    source
  }
`;

export const VideoPlaybackFragment = gql`
  fragment videoPlaybackFragment on VideoPlayback {
    id
    gameUid
    eventId
    videoUrl
    eventTime
    eventDuration
    gameTime
    event {
      ...slEventFragment
    }
    shift {
      ...slShiftFragment
    }
  }
  ${SLEventFragment}
  ${SLShiftFragment}
`;

export const BDDPlaylistFragment = gql`
  fragment bddPlaylistFragment on BDDPlaylist {
    id
    userId
    name
    description
    lastModified
    isArchived
    canEdit
    users {
      canEdit
      user {
        id
        username
      }
    }
    createdUser {
      name
    }
    clips {
      id
      playlistId
      playlistNumber
      name
      description
      frontPad
      backPad
      leadTrim
      trailTrim
      eventId
      eventName
      gameUid
      event {
        ...slEventFragment
      }
      shift {
        ...slShiftFragment
      }
    }
  }
  ${SLEventFragment}
  ${SLShiftFragment}
`;
