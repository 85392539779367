import BDDSortableTable from 'components/bdd/bddsortabletable';
import { useEPStatsContext } from './context';
import { StyledTable } from 'components/bdd/Table';
import { Typography } from 'components/bdd/Typography';
import { Container } from 'components/bdd/Layout';
import { mapSort } from 'components/bdd/Table/helpers';

export const StatsTable = ({}) => {
  const { stats, tableData, pageInfo, loading } =
    useEPStatsContext((state) => state.results) || {};
  const columns = useEPStatsContext((state) => state.columns);
  const sort = useEPStatsContext((state) => state.sort);
  const { setSort, applyFilters } = useEPStatsContext((state) => state.actions);

  if (!stats) return <Typography variant="body1">Loading...</Typography>;

  if (tableData.length == 0 || stats.data.length == 0) {
    return <Typography variant="body1">No data found</Typography>;
  }

  const sortByProps =
    !pageInfo.hasNextPage && !pageInfo.hasPreviousPage
      ? {}
      : {
          manualSortBy: true,
          onSortChange: (sort) => {
            setSort(mapSort(sort));
            applyFilters();
          },
        };

  return (
    <Container height="90%" overflow="auto">
      <StyledTable variant="sticky" rowHeight={24} withBorders withHighlight>
        <BDDSortableTable
          loading={loading}
          columns={columns}
          data={tableData}
          defaultSort={sort}
          scroll={false}
          virtualizeRows
          disableSortRemove
          {...sortByProps}
        />
      </StyledTable>
    </Container>
  );
};
