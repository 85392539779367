import { useRouteMatch } from 'react-router-dom';
import { BDDTabLink, BDDTabLinkContainer } from 'components/bdd/bddcomponents';
import { useUser } from 'helpers/user';
import { Typography } from '../Typography';
import { useState } from 'react';

export const PageTabs = ({ routes, persistSearchParams }) => {
  // if "routes" supplied with a "to" param, will use that over the "route"
  const { userHasPermission } = useUser();
  const { url: baseRoute } = useRouteMatch();

  const [searchParamHistory, setSearchParamHistory] = useState({});

  return (
    <>
      <BDDTabLinkContainer leftAlign noMargin>
        {routes
          .filter((r) => !r.requiresPermission || userHasPermission(r.requiresPermission))
          .map((r) => {
            const destination = !!r.to ? r.to : r.route
            const route = `${baseRoute}/${destination}`;
            const routeWithSearch = `${baseRoute}/${destination}${
              searchParamHistory?.[`${baseRoute}/${destination}`] || ''
            }`;
            
            return (
              <BDDTabLink
                key={route}
                to={persistSearchParams ? routeWithSearch : route}
                onHistoryChange={() => {
                  setSearchParamHistory({
                    ...searchParamHistory,
                    [document.location.pathname]: document.location.search,
                  });
                }}
                matchSearchStart
              >
                <Typography variant="h6" as="span">
                  {r.name}
                </Typography>
              </BDDTabLink>
            );
          })}
      </BDDTabLinkContainer>
    </>
  );
};
