import React from 'react';
import styled from 'styled-components';

import { theme } from 'constants';
import { HoverInteractive } from 'components/bdd';
import useToggle from 'components/bdd/Toggle/useToggle';
import { Column, Container, Row } from 'components/bdd/Layout';
import {
  getCurrentSeasonFilterValue,
  useEPData,
  StatHighlightBoxes,
  StatTable,
} from 'components/EliteProspects';
import { EPStats } from 'components/EliteProspects/v2/EPStats';
import { Typography } from 'components/bdd/Typography';

const DEFAULT_SKATER_SEASON_QUERY = {
  target: 'ep_player_season_stats',
  stats: ['GP', 'G', 'A', 'PTS', 'PM', 'PIM'],
  format: {},
};

const CompactTableStyles = styled.div({
  table: {
    ...theme.typography.body4,
  },
});

const SKATER_LIST_STATS = ['GP', 'G', 'A', 'PTS', 'PIM'];
const GOALIE_LIST_STATS = ['GP', 'SVP', 'GAA'];

export const HoverStatTable = ({ rp }) => {
  return (
    <EPStats
      index={
        rp.posFDG == 'G'
          ? 'player,player_id,league,league_slug,season,team,team_id'
          : 'player,player_id,league,league_slug,season'
      }
      filters={{
        game_type: {
          id: 'game_type',
          value: 'REGULAR_SEASON',
        },
        rinknet_id: {
          id: 'rinknet_id',
          value: rp.realId,
        },
        position: {
          id: 'position',
          value: rp.posFDG,
        },
      }}
      sort={[{ id: 'season' }]}
      indexColumnIds={['season', 'league', 'team']}
    >
      <Container width={500} onClick={(e) => e.stopPropagation()}>
        <Column gap={2}>
          <Row columnGap={2} flexWrap>
            <EPStats.TargetGroupToggle />
            <EPStats.ZScoreToggle />
          </Row>
          <CompactTableStyles>
            <EPStats.Table />
          </CompactTableStyles>
        </Column>
      </Container>
    </EPStats>
  );
};

export const EliteProspectsData = ({
  rp,
  rinknetPlayers,
  expanded = false,
  useZScore = false,
  maxLeagueWidth = null,
  season = null,
}) => {
  const gRinknetIds = rinknetPlayers
    ?.filter((rp) => rp.posFDG == 'G')
    ?.map((rp) => rp.realId);
  const skaterRinknetIds = rinknetPlayers
    ?.filter((rp) => rp.posFDG != 'G')
    ?.map((rp) => rp.realId);
  const goalieQuery = {
    ...DEFAULT_SKATER_SEASON_QUERY,
    stats: GOALIE_LIST_STATS,
    filters: [
      {
        col: 'rinknet_id',
        val: gRinknetIds,
        cond: 'isin',
      },
      {
        col: 'game_type',
        val: 'REGULAR_SEASON',
      },
      {
        col: 'season',
        val: !!season
          ? `${season.toString().slice(0, 4)}-${season.toString().slice(4)}`
          : getCurrentSeasonFilterValue(),
      },
    ],
    extras: {
      metastats: {
        target_group: ['age'],
        game_type: 'REGULAR_SEASON',
        position: ['G'],
      },
    },
    index: 'player,player_id,league,league_slug,season,team,team_id',
  };
  const skaterQuery = {
    ...DEFAULT_SKATER_SEASON_QUERY,
    stats: SKATER_LIST_STATS,
    filters: [
      {
        col: 'rinknet_id',
        val: skaterRinknetIds,
        cond: 'isin',
      },
      {
        col: 'game_type',
        val: 'REGULAR_SEASON',
      },
      {
        col: 'season',
        val: !!season
          ? `${season.toString().slice(0, 4)}-${season.toString().slice(4)}`
          : getCurrentSeasonFilterValue(),
      },
    ],
    extras: {
      metastats: {
        target_group: ['age'],
        game_type: 'REGULAR_SEASON',
        position: ['F', 'D'],
      },
    },
    index: 'player,player_id,league,league_slug,season',
  };

  const {
    stats: gStats,
    metastats: gMetastats,
    highlightCallback: gHighlightCallback,
    formatStatByZScore: gFormatStatByZScore,
    loading: gLoading,
    error: gError,
  } = useEPData({
    query: !!gRinknetIds ? goalieQuery : null,
    gameType: 'REGULAR_SEASON',
    targetGroup: 'age',
    skip: !gRinknetIds || !gRinknetIds.length,
  });
  const {
    stats: skaterStats,
    metastats: skaterMetastats,
    highlightCallback: skaterHighlightCallback,
    formatStatByZScore: skaterFormatStatByZScore,
    loading: sLoading,
    error: sError,
  } = useEPData({
    query: !!skaterRinknetIds ? skaterQuery : null,
    gameType: 'REGULAR_SEASON',
    targetGroup: 'age',
    skip: !skaterRinknetIds || !skaterRinknetIds.length,
  });

  const stats = rp.posFDG == 'G' ? gStats : skaterStats;
  const highlightCallback =
    rp.posFDG == 'G' ? gHighlightCallback : skaterHighlightCallback;
  const formatStatByZScore =
    rp.posFDG == 'G' ? gFormatStatByZScore : skaterFormatStatByZScore;
  const statSlugs = rp.posFDG == 'G' ? GOALIE_LIST_STATS : SKATER_LIST_STATS;

  if (!stats && (sLoading || gLoading)) {
    return <Typography variant="body1">Loading...</Typography>;
  } else if (sError || gError) {
    return <Typography variant="body1">EP Stats Error</Typography>;
  } else if (!stats) {
    return <Typography variant="body1">EP Stats Error</Typography>;
  }
  const playerStatsToHighlight = stats.data
    .filter((d) => d.player_rinknet_id == rp.realId)
    .sort((a, b) => b['stats_GP'] - a['stats_GP'])[0];

  if (playerStatsToHighlight) {
    return !!expanded ? (
      <HoverStatTable rp={rp} />
    ) : (
      <HoverInteractive content={<HoverStatTable rp={rp} />}>
        <StatHighlightBoxes
          playerStatsToHighlight={playerStatsToHighlight}
          slugs={statSlugs}
          formatStatByZScore={useZScore ? formatStatByZScore : null}
          highlightCallback={highlightCallback}
          maxLeagueWidth={maxLeagueWidth}
        />
      </HoverInteractive>
    );
  }

  return (
    <Container>
      <Typography variant='stat'>
        <em>No elite prospect data found</em>
      </Typography>
    </Container>
  );
};
