import { theme } from 'constants/theme';
import { Container, Row } from '../Layout';
import { Typography } from '../Typography';
import { useFilterContext } from './context';

export const FilterTag = ({ name, label }) => {
  // const filter = useFilterContext((state) => state.filters[id]);

  // if (!filter) return null;

  // console.log(filter)

  // let valueLabel;
  // switch (filter.type) {
  //   case 'inputRange':
  //     valueLabel = `${filter.value.min}-${filter.value.max}`;
  //   case 'boolean':
  //     valueLabel = !!filter.checked ? 'true' : 'false';
  //     break;
  //   case 'select':
  //   default:
  //     valueLabel = filter.value.toString();
  // }

  return (
    <Container key={name} padding={1} backgroundColor={theme.colors.light.secondary}>
      <Row columnGap={1}>
        <Typography variant="label">{name}:</Typography>
        <Typography variant="stat">{label}</Typography>
      </Row>
    </Container>
  );
};
