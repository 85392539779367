import { BDDLoader } from 'components/bdd/bddloader';
import { IconButton, LinkButton } from 'components/bdd/Button';
import { useButtonGroup } from 'components/bdd/ButtonGroup';
import { Divider } from 'components/bdd/Divider';
import { DraggableList } from 'components/bdd/DragAndDrop';
import Icon from 'components/bdd/Icon';
import { Column, Container, Row } from 'components/bdd/Layout';
import NotificationIndicator from 'components/bdd/NotificationIndicator';
import { LinkPopover } from 'components/bdd/Popover';
import { Typography } from 'components/bdd/Typography';
import { CompactNotes, useNotes } from 'components/Notes';
import {
  AddPlaylistForm,
  AddToExistingPlaylistForm,
  EditPlaylistClipForm,
  usePlaylist,
  usePlaylistMutation,
} from 'components/Video/Playlists';
import { useContext, useLayoutEffect, useRef, useState } from 'react';
import { Button } from 'react-bootstrap';
import {
  FileEarmarkPlay,
  PlusLg,
  Stickies,
  ThreeDotsVertical,
  XLg,
} from 'react-bootstrap-icons';
import { VideoClipContext } from '..';
import { VideoContext } from '../..';
import { EventShiftContext, FullGamePeriod, VideoClipFilters } from '../../components';
import { Shift } from '../../components/ShiftList';
import { Event } from '../../Filtering';
import { VideoClipSortSelect } from './VideoClipSortSelect';
import scrollIntoView from 'scroll-into-view-if-needed';

export const VideoClipList = ({ actionButtons, filters }) => {
  const { clipFilters } = useContext(VideoContext);
  const {
    clips,
    loading,
    currentClip,
    setClip,
    updateClip,
    reorderClips,
    removeClips,
    toggleAllClipsForPlaylist,
    toggleClipForPlaylist,
    toggledClips,
  } = useContext(VideoClipContext);
  const [showFilters, setShowFilters] = useState(false);
  const { playlist } = usePlaylist({ id: clips?.[0]?.playlistId });

  const playlistEvIdToNumber = {};
  playlist?.clips?.forEach((c, i) => (playlistEvIdToNumber[c.eventId] = i + 1));

  const {
    removeClipFromPlaylist,
    reorderPlaylist,
    loading: playlistUpdating,
  } = usePlaylistMutation(playlist);

  const containerRef = useRef();

  const { notes } = useNotes({
    skip: !playlist,
    entityIds: [playlist?.id],
    entityType: 'BDD_PLAYLIST',
  });

  return (
    <Column gap={2}>
      <Container paddingRight={1}>
        <Row justifyContent="space-between">
          <Row columnGap={3} flexWrap alignItems="baseline">
            <Typography variant="subtitle1">
              {!loading ? `${clips?.length} Clips` : `Loading clips...`}
            </Typography>
            {!!clipFilters && (
              <LinkButton onClick={() => setShowFilters(!showFilters)}>
                <Typography variant="body2">
                  {showFilters ? 'Hide filters' : 'Show filters'}
                </Typography>
              </LinkButton>
            )}
            {playlist && (
              <Typography variant="body2">
                {playlistUpdating ? (
                  <span>Updating...</span>
                ) : (
                  <span>
                    Playlist: <b>{playlist.name}</b>
                  </span>
                )}
              </Typography>
            )}
          </Row>
          {actionButtons}
        </Row>
      </Container>
      {showFilters && (
        <>
          <Container>{clipFilters}</Container>
          <Divider />
        </>
      )}
      <Container paddingRight={1}>
        <Row justifyContent="space-between">
          <Row columnGap={2} flexWrap>
            <input
              style={{ width: 16, height: 16 }}
              id="toggle-all-clips"
              type="checkbox"
              disabled={loading}
              onChange={(event) => toggleAllClipsForPlaylist(event.currentTarget.checked)}
            />
            <label htmlFor="toggle-all-clips" style={{ margin: 0, userSelect: 'none' }}>
              <Typography variant="body1">Select All</Typography>
            </label>
            <VideoClipSortSelect />
          </Row>
          <Container ref={containerRef}>
            <LinkPopover
              placement="auto"
              title={
                <Typography variant="body1">
                  {toggledClips.length} clips selected
                </Typography>
              }
              renderLink={(setShow, target) => (
                <Button
                  variant="outline-dark"
                  ref={target}
                  onClick={() => {
                    setShow((show) => !show);
                  }}
                  disabled={toggledClips.length == 0}
                >
                  <Typography variant="stat">
                    <Row columnGap={1}>
                      <Icon icon={<PlusLg />} />
                      <b>Add to Playlist</b>
                    </Row>
                  </Typography>
                </Button>
              )}
              renderContent={(forceClose) => (
                <AddToPlaylistForm
                  clips={toggledClips}
                  onSubmit={() => {
                    toggleAllClipsForPlaylist(false);
                    forceClose();
                  }}
                />
              )}
            />
          </Container>
        </Row>
      </Container>
      <Divider />
      <Container overflow="auto">
        {loading ? (
          <BDDLoader variant="squares" />
        ) : (
          <DraggableList
            ids={clips.map((c) => c.id)}
            renderItem={(id, index) => {
              const clip = clips?.find((c) => c.id == id);
              if (!clip) return null;

              const notesForClip = notes?.filter((n) => n.subEntityId == clip.id);

              return (
                <ClipListItem
                  playlist={playlist}
                  clip={clip}
                  index={index}
                  notesForClip={notesForClip}
                  isCurrentClip={currentClip == clip}
                  playlistUpdating={playlistUpdating}
                  playlistEvIdToNumber={playlistEvIdToNumber}
                />
              );
            }}
            noDragPreview={false}
            onChange={({ ids }) => {
              reorderClips(ids);
              if (!!playlist) reorderPlaylist({ ids });
            }}
          />
        )}
      </Container>
    </Column>
  );
};

const ClipListItem = ({
  clip,
  index,
  isCurrentClip,
  notesForClip,
  playlist,
  playlistEvIdToNumber,
}) => {
  const [details, setDetails] = useState();

  const { setClip, updateClip, removeClips, toggleClipForPlaylist } =
    useContext(VideoClipContext);
  const { removeClipFromPlaylist } = usePlaylistMutation(playlist);

  let clipContext = {};
  if (!!clip.eventId) {
    const event = clip.event;
    if (!event) return null;

    clipContext = (
      <Event
        event={event}
        number={playlistEvIdToNumber?.[event.eventId]}
        name={clip.name}
        description={clip.description}
        selected={isCurrentClip}
      />
    );
  } else if (!!clip.shift) {
    const shift = clip.shift;
    if (!shift) return null;

    clipContext = <Shift shift={shift} isSelected={() => isCurrentClip} />;
  } else {
    clipContext = <FullGamePeriod period={index} selected={isCurrentClip} />;
  }

  const ref = useRef();
  useLayoutEffect(() => {
    if (!ref?.current || !isCurrentClip) return;
    scrollIntoView(ref.current, {
      scrollMode: 'if-needed',
      behavior: 'smooth',
      block: 'end',
    });
  }, [ref, isCurrentClip]);

  return (
    <Container key={clip.id}>
      <Row columnGap={2}>
        <input
          style={{ width: 16, height: 16 }}
          type="checkbox"
          checked={clip.toggled}
          onChange={() => toggleClipForPlaylist(clip)}
        />
        <Container flexGrow onClick={() => setClip(index)} ref={ref}>
          {clipContext}
        </Container>
        {!!playlist && (
          <LinkPopover
            renderLink={(setShow, target) => (
              <Container ref={target}>
                <IconButton
                  icon={<ThreeDotsVertical />}
                  onClick={() => setShow((show) => !show)}
                />
              </Container>
            )}
            renderContent={() => (
              <Container>
                <Column gap={2} style={{ paddingRight: 4 }}>
                  <LinkButton
                    onClick={() =>
                      setDetails(
                        <CompactNotes
                          entity={{
                            entityId: clip.playlistId,
                            entityType: 'BDD_PLAYLIST',
                            subEntityId: clip.id,
                            subEntityType: 'BDD_PLAYLIST_CLIP',
                          }}
                        />
                      )
                    }
                  >
                    <Row columnGap={2}>
                      <Stickies />
                      {notesForClip?.length > 0 && (
                        <NotificationIndicator top={-6} left={-6}>
                          {notesForClip.length}
                        </NotificationIndicator>
                      )}
                      <Typography variant="body2">View Notes</Typography>
                    </Row>
                  </LinkButton>
                  <LinkButton
                    onClick={() =>
                      setDetails(
                        <EditPlaylistClipForm
                          playlist={playlist}
                          clip={clip}
                          onSubmit={(clipsToUpdate) => {
                            updateClip(clipsToUpdate[0]);
                            setDetails();
                          }}
                        />
                      )
                    }
                  >
                    <Row columnGap={2}>
                      <FileEarmarkPlay />
                      <Typography variant="body2">Edit Clip Info</Typography>
                    </Row>
                  </LinkButton>
                  <LinkButton
                    onClick={() => {
                      removeClips([clip.id]);
                      if (!!playlist) removeClipFromPlaylist(clip);
                    }}
                  >
                    <Row columnGap={2}>
                      <XLg />
                      <Typography variant="body2">Remove Clip</Typography>
                    </Row>
                  </LinkButton>
                </Column>
              </Container>
            )}
          />
          // <Dropdown
          //   renderToggle={() => <IconButton icon={<ThreeDotsVertical />} />}
          // />
        )}
      </Row>
      {details && (
        <Container paddingTop={2} paddingRight={1}>
          <Column gap={1}>
            {details}
            <Divider />
          </Column>
        </Container>
      )}
    </Container>
  );
};

const AddToPlaylistForm = ({ clips, onSubmit }) => {
  const { playlist } = useContext(VideoContext);
  const { buttonGroup, selectedValue } = useButtonGroup({
    variant: 'link',
    initialSelectedValue: !!playlist ? 'current' : 'existing',
    options: [
      {
        value: 'current',
        label: 'Current Playlist',
      },
      {
        value: 'existing',
        label: 'Existing Playlist',
      },
      {
        value: 'new',
        label: 'Add New Playlist',
      },
    ],
  });

  return (
    <Container onClick={(e) => e.stopPropagation()}>
      <Column gap={1}>
        {buttonGroup}
        {selectedValue == 'current' && (
          <AddToExistingPlaylistForm
            playlist={playlist}
            playlistClips={clips}
            onSubmit={onSubmit}
          />
        )}
        {selectedValue == 'new' && (
          <AddPlaylistForm playlistClips={clips} showPlaylistLink />
        )}
        {selectedValue == 'existing' && (
          <AddToExistingPlaylistForm playlistClips={clips} onSubmit={onSubmit} />
        )}
      </Column>
    </Container>
  );
};
