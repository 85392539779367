import React, { cloneElement } from 'react';
import { DASHBOARD_ROUTE } from '../../constants';
import { getNHLTeamLogoUrl } from '../../helpers/logos';
import { trackError } from 'helpers/tracker';

export default class BDDErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, errorInfo);
    trackError(error);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      if (this.props.errorContent) {
        return cloneElement(this.props.errorContent, { error: this.state.error });
      }
      return (
        <>
          <h4>
            Error: Something's{' '}
            <img
              src={getNHLTeamLogoUrl(6)}
              height="30px"
              style={{ marginRight: '-7px' }}
            />
            roken
          </h4>
          <p>It's probably Josh's fault.</p>
          {this.props.homeButton && (
            <div>
              <a href={`${DASHBOARD_ROUTE}`}>Go To Dashboard</a>
            </div>
          )}
        </>
      );
    }

    return this.props.children;
  }
}
