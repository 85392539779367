import { Border, Column, Container, Row } from 'components/bdd/Layout';
import { Cell, Header, buildColumns } from 'components/bdd/Table';
import { roundToX } from 'helpers/data';
import { heightInchesToStr, multiSeason8to4, season8to4 } from 'helpers/hockeyutils';
import PlayerLinkHover from 'components/PlayersV2/Hover/PlayerLinkHover';
import { TooltipSpan } from 'components/reports';
import { LinkButton } from 'components/bdd/Button';
import { Check } from 'react-bootstrap-icons';
import Icon from 'components/bdd/Icon';
import { formatDate } from 'helpers/helpers';
import React from 'react';

export const buildIndexColumns = ({ mapping } = {}) =>
  buildColumns([
    {
      id: 'player_id',
      header: 'EP ID',
      disableSort: true,
      accessor: (row) => row['player_id'],
    },
    {
      id: 'player',
      header: 'Player',
      accessor: (row) => row['player'],
      renderCell: (d, av) => {
        return <PlayerLinkHover slug={d['slug']} name={av} />;
      },
      minWidth: 150,
    },
    {
      id: 'season',
      accessor: (row) => {
        let seasons = multiSeason8to4(row['season'], true, true);
        seasons = seasons.split(', ');

        if (seasons.length == 1) return seasons[0];

        return `${seasons[0].split('-')[0]}-${seasons[seasons.length - 1].split('-')[1]}`;
      },
      header: 'Season',
    },
    {
      id: 'league',
      accessor: (row) => row['league'],
      header: 'League',
    },
    {
      id: 'current_league',
      header: 'Current League',
      accessor: (row) => {
        return mapping?.league_slug[row['current_league_slug']];
      },
    },
    {
      id: 'team',
      accessor: (row) => {
        const teams = row['team'].toString().split(', ');
        if (teams.length == 1) return teams[0];

        if (teams.length > 1)
          return (
            <TooltipSpan content={row['team']}>{`${teams.length} teams`}</TooltipSpan>
          );

        return row['team'];
      },
      header: 'Team',
      // minWidth: 300,
    },
    {
      id: 'age',
      accessor: (row) => {
        if (!row['age']) return 'Unknown';

        const ages = row['age'].toString().split(', ');
        if (ages.length == 1) return ages[0];

        return `${ages[0]}-${ages[ages.length - 1]}`;
      },
      header: 'Age',
    },
    {
      id: 'birth_date',
      accessor: (row) => formatDate(new Date(row['birth_date']), { format: '%b %d %Y' }),
      header: 'DOB',
    },
    {
      id: 'height',
      accessor: (row) => row['height'],
      renderLabel: (row, av) =>
        row['height'] > 0 ? heightInchesToStr(row['height']) : 'Unknown',
      header: 'Height',
    },
    {
      id: 'weight',
      accessor: (row) => (row['weight'] > 0 ? row['weight'] : 'Unknown'),
      header: 'Weight (lbs)',
    },
    {
      id: 'position',
      accessor: (row) => row['position'],
      header: 'Pos',
    },
    {
      id: 'draft_year',
      accessor: (row) => (row['draft_year'] == 0 ? 'N/A' : row['draft_year']),
      header: 'Year Drafted',
    },
    {
      id: 'draft_team_id',
      accessor: (row) =>
        row['draft_team_id'] && mapping ? mapping.team_id[row['draft_team_id']] : 'N/A',
      header: 'Draft Team',
    },
    {
      id: 'round',
      accessor: (row) => (row['round'] == 0 ? 'N/A' : row['round']),
      header: 'Round',
    },
    {
      id: 'overall',
      accessor: (row) => (row['overall'] == 0 ? 'N/A' : row['overall']),
      header: 'Overall',
    },
    {
      id: 'bdd_link',
      disableSort: true,
      accessor: (row) => {
        return (
          <Cell textAlign="center">
            <Row justifyContent="center">
              {row['slug'] ? (
                <Icon icon={<Check />} />
              ) : (
                <LinkButton>Not linked</LinkButton>
              )}
            </Row>
          </Cell>
        );
      },
      header: 'BDD Linked',
    },
  ]);

export const buildStatColumns = ({
  statDefinitions,
  formatStatByZScore,
  highlightCallback,
}) => {
  return buildColumns(
    statDefinitions.map((sd) => ({
      id: `stats_${sd.slug}`,
      Header: (
        <Header>
          <Container paddingLeft={0.5} paddingRight={0.5}>
            {sd.label}
          </Container>
        </Header>
      ),
      header: sd.label,
      minWidth: 30,
      accessor: (d) =>
        formatStatByZScore
          ? formatStatByZScore(d[`stats_${sd.slug}`], sd.slug, d)
          : roundToX(d[`stats_${sd.slug}`] || 0, 1),
      renderCell: (d, av) => (
        <Cell textAlign="center" isStatCell>
          <Container
            style={{
              backgroundColor: highlightCallback(sd.slug, d[`stats_${sd.slug}`], d),
            }}
          >
            {av}
          </Container>
        </Cell>
      ),
    }))
  );
};
