

export const CAP_TERM_DEFINITIONS = {
  projectedCapHit: 'Projected cap hit at the end of season if roster remainis the same. Sum of 1) past daily cap hits based on active roster on every day, 2) the  projected remaining daily cap hits if the roster stays the same and 3) dead cap',
  projectedCapSpace: 'Projected cap space at the end of the regular season accrued by opearting under the upper limit OR operating under the LTI ACSL while in LTI. Sum of 1) past daily accrued cap space, 2) the projected remaining accrued cap space if roster stays the same',
  projectedLtrirUsed: 'Projected LTI used at the end of the regular season by operating over the upper limit while in LTI. Sum of 1) past daily LTI used, 2) the projected remaining daily LTI usage if the roster stays the same',
  currentCapHit: "Full season cap hit of today's roster. Sum of cap hits from 1) active roster including injured players, 2) dead cap, and 3) buried contract penalties",
  currentCapSpace: 'The largest cap hit that could be added to the roster today. When a team is using LTI this is equal to their remaining LTI salary pool',
  rosterCapHit: 'Cap hit of current roster only, excluding dead cap and buried penaltys',
  projectedLtirUsed: 'Projected cap space gained by operating over the upper limit while in LTI',
  accruedCapSpace: 'Daily accrued cap space by operating under the Upper Limit OR in LTI and operating below the ACSL',
  accumulatedSalary: 'Season-to-date accumulated daily cap hits',
  tradeDeadlineCapSpace: 'The largest cap hit that could be added to the roster at the trade deadline. When a team is using LTI this is equal to their remaining LTI salary pool',
  retentionCount: 'Number of contract retention spots used, max is 3.',
}