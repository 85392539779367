import React, { useState } from 'react';
import { useQuery } from '@apollo/client';

import styled from 'styled-components';

import { theme } from 'constants';
import usePlaceholder from 'components/Placeholder/usePlaceholder';
import { GET_PLAYER_RINKNET_LISTS } from 'apollo/queries/players.queries';
import { Link } from 'react-router-dom';
import { buildPlayerListRoute } from 'helpers/routing';

const PlayerLists = styled.div({
  display: 'flex',
  flexDirection: 'column',
});

const FieldLabel = styled.div({
  ...theme.typography.subtitle1,
  color: theme.colors.light.text.primary,
});

const Field = styled.div({
  ...theme.typography.body1,
  color: theme.colors.light.text.primary,
});


export const PlayerScoutingLists = ({ bddPlayer, sortByLastModified }) => {
  const [publicListsToShow, setPublicListsToShow] = useState(5);
  const [scoutListsToShow, setScoutListsToShow] = useState(5);

  const { data, placeholder } = usePlaceholder(
    useQuery(GET_PLAYER_RINKNET_LISTS, {
      variables: { slug: bddPlayer.slug },
    })
  );

  if (!data) return placeholder;

  const rinknetLists = [...data.bddPlayer.playerRinknetLists].sort(
    (a, b) => a.listNumber - b.listNumber
  );

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        marginTop: theme.spacing[3],
        rowGap: theme.spacing[3],
        maxHeight: 350,
        overflowY: 'auto',
      }}
    >
      <PlayerLists>
        <FieldLabel>BDD Lists</FieldLabel>
        {bddPlayer.playerLists.length == 0 && (
          <Field>
            <em>No lists found</em>
          </Field>
        )}
        {[...bddPlayer.playerLists]
          .sort(
            (a, b) =>
              b.pinPriority - a.pinPriority || a.listNumber - b.listNumber
          )
          .slice(0, publicListsToShow)
          .map((l) => (
            <Field key={l.id}>
              #{l.listNumber} in{' '}
              <Link
                to={buildPlayerListRoute(l.playerList.listType, l.id)}
                title={l.playerList.name}
              >
                {l.playerList.name}
              </Link>
            </Field>
          ))}
        {publicListsToShow < bddPlayer.playerLists.length && (
          <Field>
            <a
              href=""
              onClick={(e) => {
                e.preventDefault();
                setPublicListsToShow(bddPlayer.playerLists.length);
              }}
            >
              Show {bddPlayer.playerLists.length - publicListsToShow} more...
            </a>
          </Field>
        )}
      </PlayerLists>
      <PlayerLists>
        <FieldLabel>Rinknet Lists</FieldLabel>
        {rinknetLists.length == 0 && (
          <Field>
            <em>No lists found</em>
          </Field>
        )}
        {rinknetLists.slice(0, scoutListsToShow).map((l) => (
          <Field key={l.id}>
            #{l.listNumber} for{' '}
            <Link
              to={buildPlayerListRoute('RINKNET', l.id)}
              title={`${l.playerList.scoutFirstName} ${l.playerList.scoutLastName}`}
            >
              {l.playerList.scoutFirstName} {l.playerList.scoutLastName} (
              {l.playerList.description})
            </Link>
          </Field>
        ))}
        {scoutListsToShow < rinknetLists.length && (
          <Field>
            <a
              href=""
              onClick={(e) => {
                e.preventDefault();
                setScoutListsToShow(rinknetLists.length);
              }}
            >
              Show {rinknetLists.length - scoutListsToShow} more...
            </a>
          </Field>
        )}
      </PlayerLists>
    </div>
  );
};
