import { useQuery } from '@apollo/client';
import { GET_BF_TEAM_SEASON_SUMMARY } from 'apollo/queries/bruinsfriendly.queries';
import { HoverInfo } from 'components/bdd';
import { LinkButton } from 'components/bdd/Button';
import { Column, Container, Row } from 'components/bdd/Layout';
import { useModal } from 'components/bdd/Modal';
import { Typography } from 'components/bdd/Typography';
import usePlaceholder from 'components/Placeholder/usePlaceholder';
import { formatDollars } from 'helpers/contracts';
import TeamCapBreakdown from './TeamCapBreakdown';
import { Divider } from 'components/bdd/Divider';

export const TeamCapBrief = ({ 
  teamSlug, 
  showMoreButton = true,
  fieldsFilter = null, // if supplied as a list of keys, will only show these fields
  truncate = false, // if true, will truncate dollars
  smallPlaceholder = false, // if true, will show a small placeholder
}) => {
  const { data, placeholder, loading, error } = usePlaceholder(
    useQuery(GET_BF_TEAM_SEASON_SUMMARY, {
      variables: { slTeamSlug: teamSlug },
    })
  );

  const teamData = data?.bfTeamSeasonSummary;
  const bfTeam = teamData?.bfTeam;

  const { showModal, setShowModal, renderModal } = useModal({
    title: `${bfTeam?.name} Cap Info`,
    size: 'xl',
  });


  if (!!placeholder)
    return smallPlaceholder ? (
      loading ? (
        <Typography variant="body2">Loading...</Typography>
      ) : null
    ) : (
      placeholder
    );
  return (
    <Container>
      {showModal && renderModal(<TeamCapBreakdown teamSlug={teamSlug} />)}
      <TeamCapBriefFields 
        bfTeam={bfTeam} 
        teamData={teamData} 
        showMoreButton={showMoreButton}
        onShowMore={() => setShowModal(true)}
        fieldsFilter={fieldsFilter}
        truncate={truncate}
      />
    </Container>
  );
}

export const TeamCapBriefFields = ({
  teamData,
  showMoreButton = true,
  onShowMore,
  fieldsFilter = null, // if supplied as a list of keys, will only show these fields
  truncate = false, // if true, will truncate dollars
}) => {


  const fullGroups = [
    [
      {
        key: 'accumulatedSalary',
        label: 'Accumulated Cap Hit',
        isDollars: true,
        info: 'Season-to-date accumulated daily cap hits from active and buried players. Excludes dead cap',
      },
      {
        key: 'projectedRemainingCapHit',
        label: 'Proj. Remaining Cap Hit',
        info: 'Sum of remaining daily roster cap hits until end of season if roster remains the same',
        isDollars: true,
      },
      {
        key: 'deadCapHit',
        label: 'Dead Cap Hit',
        info: 'All dead cap from buyouts, rentention, terminations, and performance bonus overages',
        isDollars: true,
      }
    ],
    [
      {
        key: 'accruedCapSpace',
        label: 'Accrued Cap Space',
        isDollars: true,
        info: 'Season-to-date accrued cap space by operating under the Upper Limit OR in LTI and operating below the ACSL',
      },
      {
        key: 'currentCapSpace',
        label: 'Current Cap Space',
        isDollars: true,
        info: 'The largest cap hit that could be added to the roster today. When a team is using LTI this is equal to their remaining LTI salary pool',
      },
      {
        key: 'tradeDeadlineCapSpace',
        label: 'Deadline Cap Space',
        info: 'The largest cap hit that could be added to the roster at the trade deadline. When a team is using LTI this is equal to their remaining LTI salary pool',
        isDollars: true,
      },
    ],
    [
      {
        key: 'rosterCapHit',
        label: 'Roster Cap Hit',
        info: 'Cap hit of current roster only, excluding dead cap and buried penalties',
        isDollars: true,
      },
      {
        key: 'buriedCapHit',
        label: 'Buried Cap Hit',
        info: 'Cap hit from buried contract penalties for active players not on NHL roster',
        isDollars: true,
      },
      {
        key: 'ltiAcsl',
        label: 'LTI ACSL',
        info: 'Daily LTI Accruable Cap Space Limit (ACSL) currently applicable to team. If team is in LTI and operating below this value, they will continue to accrue cap space',
        isDollars: true,
      },
    ],
    [
      {
        key: 'retentionCapHit',
        label: 'Retained Cap Hit',
        info: 'Full season sum of cap hits from retained contracts',
        isDollars: true,
      },
      {
        key: 'buyoutCapHit',
        label: 'Buyout Cap Hit',
        info: 'Full season sum of cap hits from buyouts',
        isDollars: true,
      },
      {
        key: 'pastSeasonPerfBonusOverage',
        label: 'Past Season Overage',
        info: 'Cap hit penalty from past season performance bonus overage',
        isDollars: true,
      },
    ],
  ];

  const smallGroups = [
    [
      {
        key: 'projectedCapHit',
        label: 'Proj. Cap Hit',
        isDollars: true,
        info: 'Projected cap hit at the end of season if roster remainis the same. Sum of 1) past daily cap hits based on active roster on every day, 2) the  projected remaining daily cap hits if the roster stays the same and 3) dead cap',
      },
      {
        key: 'projectedCapSpace',
        label: 'Proj. Cap Space',
        isDollars: true,
        info: 'Projected accrued cap space at the end of the regular season by opearting under the upper limit OR operating under the LTI ACSL while in LTI. Sum of 1) past daily accrued cap space, 2) the projected remaining accrued cap space if roster stays the same', 
      },
      {
        key: 'projectedLtirUsed',
        label: 'Proj. LTI Used',
        isDollars: true,
        info: 'Projected cap space gained by operating over the upper limit while in LTI',
      },
    ],
    [
      {
        key: 'currentCapHit',
        label: 'Curr. Cap Hit',
        isDollars: true,
        info: 'The current total cap hit including roster cap hits, buried cap hits, and dead cap hits',
      },
      {
        key: 'currentCapSpace',
        label: 'Curr. Cap Space',
        isDollars: true,
        info: 'The largest cap hit that could be added to the roster today. When a team is using LTI this is equal to their remaining LTI salary pool',
      },
      {
        key: 'rosterCapHit',
        label: 'Roster Cap Hit',
        isDollars: true,
        info: 'Cap hit of current roster only, excluding dead cap and buried penalties',
      },
    ],
    [
      {
        key: 'rosterSize',
        label: 'Roster',
      },
      {
        key: 'injuryCount',
        label: 'Injuries',
      },
      {
        key: 'more',
        label: (
          <LinkButton onClick={() => onShowMore()} style={{ color: '#777' }}>
            More cap info...
          </LinkButton>
        ),
        content: null,
      },
    ],
  ];

  const fieldGroups = showMoreButton ? smallGroups : fullGroups;

  
  return (
    <Container>
      <Row justifyContent="space-between" alignItems="start" gap={8}>
        {fieldGroups.map((fields, i) => (
          <Column key={i} gap={1}>
            {fields
              .filter((f) =>  (!fieldsFilter || fieldsFilter.includes(f.key)) && (teamData?.[f.key] != undefined || f.key === 'more'))
              .map(({ key, label, info, isDollars, content }) => (
                <Row key={key} gap={6}>
                  <HoverInfo content={info}>
                    <Typography
                      variant="body1"
                      // style={
                      //   !!info
                      //     ? { textDecoration: 'underline', textDecorationStyle: 'dashed' }
                      //     : {}
                      // }
                    >
                      {label}
                      {key !== 'more' && ':'}
                    </Typography>
                  </HoverInfo>
                  <Typography variant="body2">
                    {!!content
                      ? content
                      : !!isDollars
                      ? formatDollars(teamData?.[key], { truncate })
                      : teamData?.[key]}
                  </Typography>
                </Row>
              ))}
          </Column>
        ))}
      </Row>
      {/* <Divider></Divider> */}
    </Container>
  );
}
